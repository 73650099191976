import React from 'react';
import { container, sheet } from './styling';
import { IPageWithFourImagesAndOneText } from './TemplateProps';
import { TemplateImage } from './TemplateImage';
import { TemplateTextEditor } from './TemplateTextEditor';
import { PreventMarginCollapse } from './PreventMarginCollapse';

export const Template8 = (props: IPageWithFourImagesAndOneText) => {
    return (
        <div style={sheet}>
            <div style={container}>
                <TemplateImage img={props.img1} style={topSection} />
                <PreventMarginCollapse />
                <div
                    style={{
                        ...bottomSection,
                        flexDirection: 'column' as const,
                    }}
                >
                    <PreventMarginCollapse />
                    <div style={topSection}>
                        <TemplateImage img={props.img2} style={left} />
                        <TemplateImage img={props.img3} style={right} />
                    </div>
                    <PreventMarginCollapse />
                    <div style={bottomSection}>
                        <TemplateImage img={props.img4} style={left} />
                        <TemplateTextEditor
                            minHeight={259}
                            maxHeight={259}
                            maxWidth={368}
                            text={props.text}
                            style={right}
                            hideOpacityControls={true}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

const topSection = {
    display: 'flex',
    overflow: 'hidden',
    width: '100%',
    height: '50%',
    marginBottom: '0.25cm',
    position: 'relative' as const,
};

const left = {
    display: 'inlineFlex',
    marginRight: '0.25cm',
    position: 'relative' as const,
    width: '50%',
};

const right = {
    display: 'inlineFlex',
    marginLeft: '0.25cm',
    position: 'relative' as const,
    width: '50%',
};

const bottomSection = {
    display: 'flex',
    width: '100%',
    height: '50%',
    marginTop: '0.25cm',
    position: 'relative' as const,
};

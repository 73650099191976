import React from 'react';
import { Dialog, Classes, Button } from '@blueprintjs/core';
import { GridItem } from './GridItem';
import { TemplateName } from '../../models/Page';
import { PdfDragAndDrop } from './PdfDragAndDrop';

// Now here. Let's consider to create a single place for all template infos.
// Same place could tell the slot names and could use a generic mapping.
interface ITemplateThumbnail {
    name: TemplateName;
    thumbnailUrl: string;
}
const templates: ITemplateThumbnail[] = [
    { name: 'template1', thumbnailUrl: 'template1_thumbnail.png' },
    { name: 'template6', thumbnailUrl: 'template6_thumbnail.png' },
    { name: 'template2', thumbnailUrl: 'template2_thumbnail.png' },
    { name: 'template3', thumbnailUrl: 'template3_thumbnail.png' },
    { name: 'template4', thumbnailUrl: 'template4_thumbnail.png' },
    { name: 'template5', thumbnailUrl: 'template5_thumbnail.png' },
    { name: 'template7', thumbnailUrl: 'template7_thumbnail.png' },
    { name: 'template8', thumbnailUrl: 'template8_thumbnail.png' },
    { name: 'template9', thumbnailUrl: 'template9_thumbnail.png' },
    { name: 'template10', thumbnailUrl: 'template10_thumbnail.png' },
    { name: 'pdf', thumbnailUrl: 'pdf_thumbnail.png' },
    { name: 'static1', thumbnailUrl: 'static1_thumbnail.png' },
];

interface ITemplateSelectorProps {
    isOpen: boolean;
    isDisabled: boolean;
    onClose: (selected?: TemplateName, file?: File) => void;
}

export class TemplateSelector extends React.Component<
    ITemplateSelectorProps,
    { pdfFileSelectorOpen: boolean }
> {
    public constructor(props: ITemplateSelectorProps) {
        super(props);
    }

    public state = { pdfFileSelectorOpen: false };

    public render() {
        return (
            <div>
                <Dialog
                    style={{ width: 'auto' }}
                    isOpen={this.props.isOpen}
                    onClose={() => this.props.onClose()}
                    title="Valitse sivupohja"
                    onClosed={() => {
                        this.setState((prevState) => {
                            return { ...prevState, isDisabled: false };
                        });
                    }}
                >
                    <div
                        className={Classes.DIALOG_BODY}
                        style={{ width: '100%', maxWidth: '1020px' }}
                    >
                        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                            {templates.map((t, index) => {
                                // TODO - put some id to template-items which can be used as key
                                return (
                                    <div
                                        key={index}
                                        style={{
                                            opacity: this.props.isDisabled
                                                ? 0.3
                                                : 1,
                                        }}
                                    >
                                        <GridItem
                                            onSelect={() => {
                                                this.setState(
                                                    (prevState) => {
                                                        return {
                                                            ...prevState,
                                                            isDisabled: true,
                                                        };
                                                    },
                                                    () =>
                                                        this.templateSelected(
                                                            t.name,
                                                        ),
                                                );
                                            }}
                                            key={t.name}
                                        >
                                            <img src={t.thumbnailUrl} />
                                        </GridItem>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    <div className={Classes.DIALOG_FOOTER}>
                        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                            <Button
                                onClick={() => this.props.onClose()}
                                disabled={this.props.isDisabled}
                            >
                                Sulje
                            </Button>
                        </div>
                    </div>
                </Dialog>
                <PdfDragAndDrop
                    isOpen={this.state.pdfFileSelectorOpen}
                    pdfFileSelected={this.onPdfFileSelected}
                />
            </div>
        );
    }

    private templateSelected = (name: TemplateName) => {
        if (name === 'pdf') {
            this.setState((prevState) => {
                return {
                    ...prevState,
                    pdfFileSelectorOpen: true,
                };
            });
        } else {
            this.props.onClose(name);
        }
    };

    private onPdfFileSelected = (file?: File) => {
        this.setState((prevState) => {
            return {
                ...prevState,
                pdfFileSelectorOpen: false,
            };
        });
        if (file) {
            this.props.onClose('pdf', file);
        }
    };
}

import {
    Button,
    Card,
    Elevation,
    FormGroup,
    InputGroup,
    Callout,
    Intent,
} from '@blueprintjs/core';
import React from 'react';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { IAuthData } from './Authenticator';
import './LoginForm.scss';

interface stateType {
    from: { pathname: string };
}

interface ILoginState {
    username: string;
    password: string;
    loginFailed: boolean;
}

interface IProps extends RouteComponentProps<any> {
    handleLogin: (data: IAuthData) => Promise<boolean>;
    isAuthenticated: boolean;
}

export class LoginForm extends React.Component<IProps, ILoginState> {
    constructor(props: IProps, context: any) {
        super(props, context);
        this.state = {
            username: '',
            password: '',
            loginFailed: false,
        };
    }

    public handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const name = e.target.name;
        const value = e.target.value;
        this.setState((prevstate) => {
            const newState = { ...prevstate };
            if (name === 'username') {
                newState.username = value;
            } else if (name === 'password') {
                newState.password = value;
            } else {
                throw new Error('No username or parword field found');
            }

            return newState;
        });
    };

    public handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const loginFailed = () => {
            this.setState((prevState) => {
                return {
                    ...prevState,
                    loginFailed: true,
                };
            });
        };
        this.props.handleLogin(this.state).then((success) => {
            if (!success) {
                loginFailed();
            }
        });
    };

    public render() {
        const { from } =
            this.props.location &&
            this.props.location.state &&
            (this.props.location.state as stateType).from
                ? (this.props.location.state as stateType)
                : { from: { pathname: '/' } };

        if (this.props.isAuthenticated) {
            return <Redirect to={from} />;
        }
        return (
            <div className="login-container">
                <Card
                    className="login-card"
                    interactive={true}
                    elevation={Elevation.TWO}
                >
                    <div className="login-image-container">
                        <img
                            className="login-image"
                            src="./aninkainen_avi_logo.png"
                        />
                    </div>
                    <h2 className="login-topic">Kirjautuminen</h2>
                    {this.state.loginFailed && (
                        <Callout intent={Intent.WARNING}>
                            Kirjautuminen ei onnistunut.
                            <br />
                            Tarkista käyttäjätunnus ja salasana.
                        </Callout>
                    )}

                    <form onSubmit={this.handleSubmit}>
                        <FormGroup label="Käyttäjätunnus">
                            <InputGroup
                                name="username"
                                type="text"
                                placeholder="Syötä käyttäjätunnus"
                                onChange={this.handleChange}
                            />
                        </FormGroup>
                        <FormGroup label="Salasana">
                            <InputGroup
                                name="password"
                                type="password"
                                placeholder="Syötä salasana..."
                                onChange={this.handleChange}
                            />
                        </FormGroup>
                        <Button text="Kirjaudu" type="submit" />
                    </form>

                    <p className="login-forgot-password">
                        <a href="/auth/password_reset/">
                            Unohtuiko salasanasi?
                        </a>
                    </p>
                </Card>
            </div>
        );
    }
}

import {
    Alignment,
    Button,
    Navbar,
    Position,
    Popover,
    Menu,
} from '@blueprintjs/core';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { IUser } from './Models';
import './TopNavbar.scss';

interface IProps {
    user?: IUser;
    header?: {
        value: string;
        editable: boolean;
    };
    onLogoutClicked?: () => void;
}

export class TopNavbar extends React.Component<IProps> {
    public static defaultProps: IProps = {
        user: undefined,
    };

    public render() {
        return this.props.user ? (
            <Navbar fixedToTop className="top-navbar">
                <Navbar.Group align={Alignment.LEFT}>
                    <div className="top-navbar-logo-container">
                        <img
                            className="top-navbar-logo"
                            src="./avi_tekstilogo.png"
                        />
                    </div>
                    <Navbar.Divider />
                    <Link to="/">
                        <Button minimal icon="list" text="Listaa esitteet" />
                    </Link>
                </Navbar.Group>
                <Navbar.Group align={Alignment.RIGHT}>
                    <Navbar.Divider />

                    <Popover
                        content={
                            <Menu>
                                <Menu.Item
                                    icon="key"
                                    text="Vaihda salasana"
                                    onClick={() =>
                                        window.open('/auth/password_change/')
                                    }
                                />
                                <Menu.Divider />
                                <Menu.Item
                                    icon="log-out"
                                    text="Kirjaudu ulos"
                                    onClick={this.props.onLogoutClicked}
                                />
                            </Menu>
                        }
                        position={Position.BOTTOM_LEFT}
                    >
                        <Button minimal icon="user">
                            {this.props.user.username}
                        </Button>
                    </Popover>
                </Navbar.Group>
            </Navbar>
        ) : (
            <div />
        );
    }
}

import React, { CSSProperties } from 'react';
import { Dialog, Classes, Button, Card, Elevation } from '@blueprintjs/core';
import { ITemplateImage, ITemplateIcon } from '../../models/TemplateParameter';
import { IDimensions } from '../../models/Dimensions';
import { croppingToStyle } from '../../templates/utils';
import { Icon } from '../common/Icons';
import { IconNames } from '@blueprintjs/icons';
import './IconPlacer.scss';

interface IIconDefinition {
    fileName: string;
    defaultWidth: number;
}

const iconDefinitions: IIconDefinition[] = [
    { fileName: 'a_markkeri_musta.png', defaultWidth: 100 },
    { fileName: 'a_markkeri_valkoinen.png', defaultWidth: 100 },
    { fileName: 'logo_musta.png', defaultWidth: 250 },
    { fileName: 'logo_valkoinen.png', defaultWidth: 250 },
    { fileName: 'myyty_musta.png', defaultWidth: 250 },
    { fileName: 'myyty_valkoinen.png', defaultWidth: 250 },
    { fileName: 'juuri_myyntiin_musta.png', defaultWidth: 250 },
    { fileName: 'juuri_myyntiin_valkoinen.png', defaultWidth: 250 },
    { fileName: 'nyt_myynnissa_musta.png', defaultWidth: 250 },
    { fileName: 'nyt_myynnissa_valkoinen.png', defaultWidth: 250 },
    { fileName: 'aninkainen_toimitilat_musta.png', defaultWidth: 250 },
    { fileName: 'aninkainen_toimitilat_valkoinen.png', defaultWidth: 250 },
];

interface IIconPlacement {
    readonly id: string;
    readonly fileName: string;
    readonly point: IPoint;
    readonly width: number;
}

type IconInfo = IExistingIcon | INewIcon;

interface IExistingIcon {
    readonly kind: 'existing';
    readonly id: string;
}

interface INewIcon {
    readonly kind: 'new';
    readonly iconDefinition: IIconDefinition;
}

interface IPoint {
    readonly x: number;
    readonly y: number;
}

interface IIconDragData {
    readonly info: IconInfo;
    readonly dragPoint: IPoint;
    readonly dimensions: IDimensions;
}

interface IIconPlacerState {
    readonly icons: ReadonlyArray<IIconPlacement>;
    readonly cropping?: CSSProperties;
}

interface IIConPlacerProps {
    readonly isOpen: boolean;
    readonly image: ITemplateImage;
    readonly slotSize: IDimensions;
    readonly icons: ReadonlyArray<ITemplateIcon>;
    readonly onClose: (icons?: ITemplateIcon[]) => void;
}

class IconPlacerImage extends React.Component<
    {
        icon: IIconPlacement;
        onDrag: (ev: React.DragEvent<HTMLImageElement>, info: IconInfo) => void;
        onDelete: (icon: IIconPlacement) => void;
        onGrowIcon: (icon: IIconPlacement) => void;
        onShrinkIcon: (icon: IIconPlacement) => void;
    },
    { hovered: boolean }
> {
    public state = { hovered: false };

    public render() {
        const icon = this.props.icon;
        return (
            <div
                onMouseOver={() => this.setState({ hovered: true })}
                onMouseOut={() => this.setState({ hovered: false })}
                key={icon.id}
                className="icon-placer-image"
                style={{
                    marginTop: icon.point.y,
                    marginLeft: icon.point.x,
                }}
            >
                <div className="icon-placer-image-remove-icon">
                    <Icon
                        hovered={this.state.hovered}
                        defaultOpacity={0.0}
                        icon={IconNames.DELETE}
                        iconSize={24}
                        tooltip="Poista"
                        onClick={() => this.props.onDelete(icon)}
                    />
                </div>
                <div className="icon-placer-image-plus-icon">
                    <Icon
                        hovered={this.state.hovered}
                        defaultOpacity={0.0}
                        icon={IconNames.ADD}
                        iconSize={24}
                        tooltip="Kasvata kokoa"
                        onClick={() => this.props.onGrowIcon(icon)}
                    />
                </div>
                <div className="icon-placer-image-minus-icon">
                    <Icon
                        hovered={this.state.hovered}
                        defaultOpacity={0.0}
                        icon={IconNames.REMOVE}
                        iconSize={24}
                        tooltip="Pienennä kokoa"
                        onClick={() => this.props.onShrinkIcon(icon)}
                    />
                </div>
                <img
                    src={icon.fileName}
                    width={icon.width}
                    id={icon.id}
                    draggable={true}
                    onDragStart={(e) =>
                        this.props.onDrag(e, {
                            kind: 'existing',
                            id: icon.id,
                        })
                    }
                />
            </div>
        );
    }
}

export class IconPlacer extends React.Component<
    IIConPlacerProps,
    IIconPlacerState
> {
    public state: IIconPlacerState = {
        icons: this.toIconPlacements(this.props.icons),
        cropping: undefined,
    };

    private divRef?: React.RefObject<HTMLDivElement> = undefined;

    public componentDidMount() {
        croppingToStyle(this.props.slotSize, this.props.image).then((c) => {
            this.setState((prevState) => {
                return {
                    ...prevState,
                    cropping: c,
                };
            });
        });
    }

    public render() {
        const cropping = this.state.cropping;
        if (!cropping) {
            return <div />;
        }

        this.divRef = React.createRef<HTMLDivElement>();
        return (
            <Dialog
                className="icon-placer-dialog"
                isOpen={this.props.isOpen}
                onClose={() => this.props.onClose()}
                title="Aseta ikoneja"
            >
                <div
                    className={Classes.DIALOG_BODY + ' icon-placer-dialog-body'}
                >
                    <div
                        ref={this.divRef}
                        className="icon-placer-image-container"
                        style={{
                            width: this.props.slotSize.width,
                            height: this.props.slotSize.height,
                        }}
                        onDrop={this.drop}
                        onDragOver={this.allowDrop}
                    >
                        {this.state.icons.map((icon) => (
                            <IconPlacerImage
                                key={icon.id}
                                icon={icon}
                                onDrag={this.drag}
                                onDelete={this.onDelete}
                                onGrowIcon={() => this.changeIconSize(icon, 10)}
                                onShrinkIcon={() =>
                                    this.changeIconSize(icon, -10)
                                }
                            />
                        ))}
                        <img
                            src={this.props.image.image.mediumUrl}
                            style={cropping}
                            draggable={false}
                        />
                    </div>
                    <div className="icon-placer-library" draggable={false}>
                        {iconDefinitions.map((def, i) => {
                            return (
                                <Card
                                    key={i}
                                    className="icon-placer-library-card"
                                    elevation={Elevation.ZERO}
                                    interactive={true}
                                    draggable={false}
                                >
                                    <div
                                        className="icon-placer-library-card-frame"
                                        draggable={false}
                                    >
                                        <img
                                            draggable={true}
                                            onDragStart={(e) =>
                                                this.drag(e, {
                                                    kind: 'new',
                                                    iconDefinition: def,
                                                })
                                            }
                                            className="icon-placer-library-card-image"
                                            src={def.fileName}
                                        />
                                    </div>
                                </Card>
                            );
                        })}
                    </div>
                </div>
                <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Button onClick={() => this.props.onClose()}>
                            Peruuta
                        </Button>
                        <Button onClick={this.onClose}>Ok</Button>
                    </div>
                </div>
            </Dialog>
        );
    }

    private toIconPlacements(icons: ReadonlyArray<ITemplateIcon>) {
        let runningId = 0;
        return icons.map((icon) => {
            return {
                id: 'genIconId' + runningId++,
                fileName: icon.url,
                point: { x: icon.x, y: icon.y },
                width: icon.width,
            };
        });
    }

    private onClose = () => {
        const icons = this.state.icons.map((i) => {
            return {
                url: i.fileName,
                x: i.point.x,
                y: i.point.y,
                width: i.width,
            };
        });
        this.props.onClose(icons);
    };

    private onDelete = (icon: IIconPlacement) => {
        this.setState((prevState) => {
            const idx = prevState.icons.indexOf(icon);
            const newIcons = [...prevState.icons];
            newIcons.splice(idx, 1);
            return {
                ...prevState,
                icons: newIcons,
            };
        });
    };

    private changeIconSize(icon: IIconPlacement, widthChange: number) {
        this.setState((prevState) => {
            const newIcons = this.updateExisting(
                prevState,
                icon.id,
                undefined,
                icon.width + widthChange,
            );
            return {
                ...prevState,
                icons: newIcons,
            };
        });
    }

    private allowDrop = (ev: any) => {
        ev.preventDefault();
    };

    private drag = (ev: React.DragEvent<HTMLImageElement>, info: IconInfo) => {
        const iconDragPoint = this.getIconDragPoint(ev);
        const iconRect = this.getBoundingRect(ev.target);

        const data: IIconDragData = {
            info,
            dragPoint: iconDragPoint,
            dimensions: { width: iconRect.width, height: iconRect.height },
        };
        ev.dataTransfer.setData('iconDragData', JSON.stringify(data));
    };

    private getIconDragPoint(ev: React.DragEvent<HTMLImageElement>) {
        const rect = this.getBoundingRect(ev.target);
        const x = ev.clientX - rect.left;
        const y = ev.clientY - rect.top;
        return { x, y };
    }

    // Note that:
    // - x grows from left to right (margin-left).
    // - y grows from top to bottom (margin-top).
    private drop = (ev: React.DragEvent<HTMLDivElement>) => {
        ev.preventDefault();
        const icon: IIconDragData = JSON.parse(
            ev.dataTransfer.getData('iconDragData'),
        );

        const divRect = this.getBoundingRect(this.divRef!.current!);
        const dropPoint = this.getDropPoint(ev, divRect, icon.dragPoint);
        const limited = this.limitCoordinates(
            dropPoint,
            this.props.slotSize,
            icon.dimensions,
        );

        this.updateState(icon.info, limited);
    };

    // Get drop point that takes the start drag point on icon into account.
    private getDropPoint(
        ev: React.DragEvent<HTMLDivElement>,
        divRect: ClientRect,
        dragPoint: IPoint,
    ): IPoint {
        const dropX = ev.pageX - divRect.left;
        const dropY = ev.pageY - divRect.top;

        const x = dropX - dragPoint.x;
        const y = dropY - dragPoint.y;

        return { x, y };
    }

    // Don't let icon be placed outside of div area.
    private limitCoordinates(
        coords: IPoint,
        slotDimensions: IDimensions,
        iconDimensions: IDimensions,
    ): IPoint {
        const minX = 0;
        const maxX = slotDimensions.width - iconDimensions.width;
        const x = Math.min(Math.max(minX, coords.x), maxX);

        const minY = 0;
        const maxY = slotDimensions.height - iconDimensions.height;
        const y = Math.min(Math.max(minY, coords.y), maxY);

        return { x, y };
    }

    private getBoundingRect(target: EventTarget | HTMLDivElement): ClientRect {
        return (target as any).getBoundingClientRect(); // Typing is missing.
    }

    private updateState(info: IconInfo, point: IPoint) {
        this.setState((prevState) => {
            const newIcons =
                info.kind === 'existing'
                    ? this.updateExisting(prevState, info.id, point)
                    : this.addNew(prevState, info.iconDefinition, point);
            return {
                ...prevState,
                icons: newIcons,
            };
        });
    }

    private updateExisting(
        prevState: IIconPlacerState,
        id: string,
        newPoint?: IPoint,
        newWidth?: number,
    ): IIconPlacement[] {
        const existing = prevState.icons.find((i) => i.id === id)!;
        const idx = prevState.icons.indexOf(existing);
        const icons = [...prevState.icons];
        icons[idx] = {
            ...existing,
            point: newPoint ? newPoint : existing.point,
            width: newWidth ? newWidth : existing.width,
        };
        return [...icons];
    }

    private counter = 0;

    private addNew(
        prevState: IIconPlacerState,
        iconDef: IIconDefinition,
        point: IPoint,
    ): IIconPlacement[] {
        return [
            ...prevState.icons,
            {
                point,
                fileName: iconDef.fileName,
                id: 'generatedIconId' + this.counter++,
                width: iconDef.defaultWidth,
            },
        ];
    }
}

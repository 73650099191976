import React from 'react';
import { container, sheet } from './styling';

export const Static1 = () => {
    return (
        <div style={sheet}>
            <div style={container}>
                <div style={imageSection}>
                    <img style={image} src="./static1_image.png" />
                </div>
            </div>
        </div>
    );
};

const imageSection = {
    display: 'flex',
    overflow: 'hidden',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
};

const image = {
    width: '100%',
};

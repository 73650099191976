import { Card, Elevation } from '@blueprintjs/core';
import React from 'react';
import './grid.css';

export class GridItem extends React.Component<{
    onSelect: () => void;
}> {
    public render() {
        return (
            <Card
                className={'grid-item'}
                elevation={Elevation.ZERO}
                interactive={true}
                style={{
                    margin: '10px',
                    padding: '0',
                    borderRadius: '0',
                    width: '150px',
                }}
                onClick={this.props.onSelect}
            >
                {this.props.children}
            </Card>
        );
    }
}

import {
    Button,
    Card,
    Elevation,
    Intent,
    Popover,
    H5,
    PopoverInteractionKind,
    PopoverPosition,
    ButtonGroup,
} from '@blueprintjs/core';
import React from 'react';
import { IconNames } from '@blueprintjs/icons';

export interface IEstateProps {
    readonly thumbnailImage: string;
    readonly description: string;
    readonly isRemoved: boolean;
    readonly purgeAt: Date;
    readonly onClick: () => void;
    readonly export: () => void;
    readonly delete: () => void;
    readonly restore: () => void;
}

export class Estate extends React.Component<
    IEstateProps,
    { showRemoveDialog: boolean }
> {
    public render() {
        return (
            <div className="list-view-item">
                <Card
                    className="list-view-item-card"
                    elevation={Elevation.TWO}
                    interactive
                    onClick={this.props.onClick}
                >
                    {this.props.isRemoved && (
                        <div className="list-view-item-deleted">Poistettu</div>
                    )}
                    {this.renderNearPurge(this.props.purgeAt)}
                    <h4 className="list-view-item-description">
                        {this.props.description}
                    </h4>
                    <img
                        className="list-view-item-thumbnail"
                        src={this.props.thumbnailImage}
                    />
                    <div className="list-view-item-info">
                        <div className="list-view-item-button-container">
                            <ButtonGroup minimal={true}>
                                <Button
                                    icon="download"
                                    text="Lataa"
                                    onClick={(e: any) => {
                                        this.props.export();
                                        e.stopPropagation();
                                        e.nativeEvent.stopImmediatePropagation();
                                    }}
                                    intent={Intent.PRIMARY}
                                />
                                {this.props.isRemoved ? (
                                    <RestoreButton
                                        onRestore={this.props.restore}
                                    />
                                ) : (
                                    <RemoveButton
                                        onDelete={this.props.delete}
                                    />
                                )}
                            </ButtonGroup>
                        </div>
                    </div>
                </Card>
            </div>
        );
    }

    private renderNearPurge(purgeAt: Date) {
        const twoWeeksMs = 1209600000;
        const diffMs = purgeAt.getTime() - new Date().getTime();
        if (diffMs > twoWeeksMs) {
            return null;
        }
        const formatDate = (d: Date) => {
            return `${d.getDate()}.${d.getMonth() + 1}.${d.getFullYear()}`;
        };
        return (
            <div className="list-view-item-near-purge">
                Poistetaan {formatDate(purgeAt)}
            </div>
        );
    }
}

const RestoreButton = (props: { readonly onRestore: () => void }) => {
    return (
        <Button
            style={{
                marginLeft: 10,
            }}
            onClick={(e: any) => {
                props.onRestore();
                e.stopPropagation();
            }}
            intent={Intent.NONE}
            text="Palauta"
            icon={IconNames.UNDO}
        />
    );
};

class RemoveButton extends React.Component<
    { readonly onDelete: () => void },
    { showRemoveDialog: boolean }
> {
    public state = { showRemoveDialog: false };

    public render() {
        return (
            <Popover
                isOpen={this.state.showRemoveDialog}
                interactionKind={PopoverInteractionKind.CLICK}
                position={PopoverPosition.BOTTOM_LEFT}
            >
                <Button
                    style={{
                        marginLeft: 10,
                    }}
                    onClick={(e: any) => {
                        this.setState({ showRemoveDialog: true });
                        e.stopPropagation();
                    }}
                    intent={Intent.DANGER}
                    text="Poista"
                    icon={IconNames.DELETE}
                />
                <div
                    style={{
                        padding: 10,
                    }}
                    onClick={(e: any) => {
                        e.stopPropagation();
                    }}
                >
                    <H5>Kohteen poisto</H5>
                    <p>Haluatko varmasti poistaa kohteen?</p>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            marginTop: 15,
                        }}
                    >
                        <Button
                            style={{
                                marginRight: 10,
                            }}
                            onClick={(e: any) => {
                                e.stopPropagation();
                                this.setState({ showRemoveDialog: false });
                            }}
                        >
                            Peruuta
                        </Button>
                        <Button
                            intent={Intent.DANGER}
                            onClick={(e: any) => {
                                e.stopPropagation();
                                this.props.onDelete();
                                this.setState({ showRemoveDialog: false });
                            }}
                        >
                            Poista
                        </Button>
                    </div>
                </div>
            </Popover>
        );
    }
}

import React, { Component, ChangeEvent } from 'react';
import {
    Dialog,
    Button,
    Intent,
    Classes,
    Card,
    Elevation,
} from '@blueprintjs/core';
export class PdfDragAndDrop extends Component<
    { isOpen: boolean; pdfFileSelected: (file?: File) => void },
    { dragging: boolean; selected?: File }
> {
    public state = {
        dragging: false,
        selected: undefined,
    };

    private dragCounter = 0;
    private dropRef: React.RefObject<HTMLDivElement> = React.createRef<
        HTMLDivElement
    >();

    public render() {
        return (
            <Dialog
                style={{ width: 'auto' }}
                isOpen={this.props.isOpen}
                title="Valitse pdf-tiedosto"
                onOpened={this.dialogOpened}
            >
                <div className={Classes.DIALOG_BODY}>
                    <div>
                        <input
                            type="file"
                            multiple={false}
                            // eslint-disable-next-line
                            ref="fileUploader"
                            accept=".pdf"
                            onChange={this.onFileBrowserChange}
                            style={{ display: 'none' }}
                        />
                    </div>

                    <div
                        style={{
                            display: 'inline-block',
                            position: 'relative',
                        }}
                        ref={this.dropRef}
                    >
                        {this.state.dragging && (
                            <div
                                style={{
                                    border: 'dashed grey 4px',
                                    backgroundColor: 'rgba(255,255,255,.8)',
                                    position: 'absolute',
                                    top: 0,
                                    bottom: 0,
                                    left: 0,
                                    right: 0,
                                    zIndex: 9999,
                                }}
                            >
                                <div
                                    style={{
                                        position: 'absolute',
                                        top: '50%',
                                        right: 0,
                                        left: 0,
                                        textAlign: 'center',
                                        color: 'grey',
                                        fontSize: 36,
                                    }}
                                />
                            </div>
                        )}
                        <Card elevation={Elevation.ZERO} interactive={true}>
                            <div
                                style={{
                                    height: 300,
                                    width: 250,
                                    textAlign: 'center',
                                    background: '#f1f0ef',
                                }}
                                onClick={this.openFileBrowser}
                            >
                                <div style={{ paddingTop: 20 }}>
                                    Raahaa ja pudota tai <a>avaa</a>{' '}
                                    pdf-tiedosto.
                                </div>
                                {this.state.selected && (
                                    <div>
                                        <div style={{ paddingTop: 20 }}>
                                            <img
                                                src="pdf-file.png"
                                                width="60%"
                                            />
                                        </div>
                                        <div style={{ overflow: 'hidden' }}>
                                            {
                                                (this.state.selected! as File)
                                                    .name
                                            }
                                        </div>
                                    </div>
                                )}
                            </div>
                        </Card>
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            marginTop: 15,
                        }}
                    >
                        <Button
                            text="OK"
                            intent={Intent.PRIMARY}
                            style={{ marginRight: 10 }}
                            onClick={() => {
                                const file = this.clearFile();
                                this.props.pdfFileSelected(file);
                            }}
                        />
                        <Button
                            text="Peruuta"
                            onClick={() => {
                                this.clearFile();
                                this.props.pdfFileSelected(undefined);
                            }}
                        />
                    </div>
                </div>
            </Dialog>
        );
    }

    public UNSAFE_componentWillUnmount() {
        if (!this.props.isOpen) {
            return;
        }
        const div = this.dropRef.current!;
        div.ondragenter = null;
        div.ondragleave = null;
        div.ondragover = null;
        div.ondrop = null;
    }

    private dialogOpened = () => {
        const div = this.dropRef.current!;
        div.ondragenter = this.handleDragIn;
        div.ondragleave = this.handleDragOut;
        div.ondragover = this.handleDrag;
        div.ondrop = this.handleDrop;
    };

    private stopEvent(e: DragEvent) {
        e.preventDefault();
        e.stopPropagation();
    }

    private handleDrag = (e: DragEvent) => {
        this.stopEvent(e);
    };

    private handleDragIn = (e: DragEvent) => {
        this.stopEvent(e);
        this.dragCounter++;
        if (
            e.dataTransfer &&
            e.dataTransfer.items &&
            e.dataTransfer.items.length > 0
        ) {
            this.setState({ dragging: true });
        }
    };

    private handleDragOut = (e: DragEvent) => {
        this.stopEvent(e);
        this.dragCounter--;
        if (this.dragCounter === 0) {
            this.setState({ dragging: false });
        }
    };

    private handleDrop = (e: DragEvent) => {
        this.stopEvent(e);
        this.setState({ dragging: false });
        if (
            e.dataTransfer &&
            e.dataTransfer.files &&
            e.dataTransfer.files.length > 0
        ) {
            const file = e.dataTransfer.files
                ? e.dataTransfer.files[0]
                : undefined;
            this.onFileSelected(file);
            this.dragCounter = 0;
        }
    };

    private openFileBrowser = () => {
        // eslint-disable-next-line
        (this.refs.fileUploader as any).click();
    };

    private onFileBrowserChange = (event: ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files ? event.target.files[0] : undefined;
        this.onFileSelected(file);
    };

    private onFileSelected(file?: File) {
        if (file && this.isPdf(file.name)) {
            this.setState((prevState) => {
                return {
                    ...prevState,
                    selected: file,
                };
            });
        }
    }

    private clearFile() {
        const file = this.state.selected;
        this.setState((prevState) => {
            return {
                ...prevState,
                selected: undefined,
            };
        });
        return file;
    }

    private isPdf(filename: string) {
        return filename.split('.').slice(-1)[0] === 'pdf';
    }
}

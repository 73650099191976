import React, { CSSProperties } from 'react';
import { EditIcons } from './EditIcons';
import { ITemplateImageProps } from './TemplateProps';
import { ITemplateImage } from '../models/TemplateParameter';
import './TemplateImage.scss';
import { croppingToStyle } from './utils';
import { IDimensions } from '../models/Dimensions';

export class TemplateImage extends React.Component<
    { img: ITemplateImageProps; style: CSSProperties },
    {
        calculationsForImg: ITemplateImage | undefined;
        divDimensions: Dimensions | undefined;
        cropping: CSSProperties | undefined;
    }
> {
    private divRef?: React.RefObject<HTMLDivElement> = undefined;

    public state = {
        calculationsForImg: undefined,
        divDimensions: undefined,
        cropping: undefined,
    };

    public async componentDidMount() {
        const getDivDimensions = (
            divRef: React.RefObject<HTMLDivElement>,
        ): Dimensions => {
            if (!divRef.current) {
                throw new Error(
                    `Missing divRef. State: ${JSON.stringify(this.state)}`,
                );
            }
            return new Dimensions(
                divRef.current.clientWidth,
                divRef.current.clientHeight,
            );
        };

        this.setState({
            divDimensions: getDivDimensions(this.divRef!),
            cropping: undefined,
        });
    }

    public componentDidUpdate() {
        if (this.state.calculationsForImg !== this.props.img.img) {
            this.setState(
                (prevState) => {
                    return {
                        ...prevState,
                        calculationsForImg: this.props.img.img,
                    };
                },
                () => this.reCrop(),
            );
        }
    }

    private reCrop() {
        if (this.state.divDimensions && this.props.img.img) {
            croppingToStyle(this.state.divDimensions!, this.props.img.img).then(
                (style) => {
                    this.setState((prevState) => {
                        return {
                            ...prevState,
                            cropping: style,
                        };
                    });
                },
            );
        }
    }

    public render() {
        this.divRef = React.createRef<HTMLDivElement>();

        return (
            <div
                className="template-image-container"
                ref={this.divRef}
                style={this.props.style}
            >
                {this.state.divDimensions && (
                    <EditIcons
                        imageSet={this.props.img.img !== undefined}
                        onChangeImage={() =>
                            this.props.img.onChangeImage(this.getAspectRatio())
                        }
                        onCropImage={() =>
                            this.props.img.onCropImage(this.getAspectRatio())
                        }
                        onSetMarker={() =>
                            this.props.img.onSetMarker(this.getSlotSize())
                        }
                    >
                        {this.props.img.img && this.state.cropping ? (
                            <div className="icon-and-image-container">
                                {this.props.img.icons.map((icon, index) => {
                                    return (
                                        <div
                                            key={index}
                                            className="single-icon"
                                            style={{
                                                marginTop: icon.y,
                                                marginLeft: icon.x,
                                            }}
                                        >
                                            <img
                                                src={icon.url}
                                                width={icon.width}
                                            />
                                        </div>
                                    );
                                })}
                                <img
                                    style={this.state.cropping}
                                    src={this.props.img.img.image.mediumUrl}
                                />
                            </div>
                        ) : (
                            <div
                                className="image-missing-container"
                                onClick={() =>
                                    this.props.img.onChangeImage(
                                        this.getAspectRatio(),
                                    )
                                }
                            >
                                {this.props.img.img == undefined && (
                                    <img src="./house_icon.png" />
                                )}
                            </div>
                        )}
                    </EditIcons>
                )}
            </div>
        );
    }

    private getSlotSize() {
        return {
            width: (this.state.divDimensions! as Dimensions).width,
            height: (this.state.divDimensions! as Dimensions).height,
        };
    }

    private getAspectRatio() {
        return (this.state.divDimensions! as Dimensions).getAspectRatio();
    }
}

class Dimensions implements IDimensions {
    constructor(
        public readonly width: number,
        public readonly height: number,
    ) {}

    public getAspectRatio() {
        return this.width / this.height;
    }
}

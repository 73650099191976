import React, { ErrorInfo } from 'react';
import { ErrorOverlay } from './components/common/ErrorOverlay';

export class ErrorBoundary extends React.Component<
    unknown,
    { error?: Error; errorInfo?: ErrorInfo }
> {
    public state = { error: undefined, errorInfo: undefined };

    public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        this.showError(error, errorInfo);
    }

    public render() {
        return (
            <div className="error-boundary">
                {this.props.children}
                {this.state.error && (
                    <ErrorOverlay
                        isOpen={!!this.state.error}
                        error={{
                            error: (this.state.error! as Error).toString(),
                            errorInfo: this.state.errorInfo,
                        }}
                        onClose={this.hideError}
                    />
                )}
            </div>
        );
    }

    private showError = (error: Error, errorInfo: ErrorInfo) => {
        this.setState((prevState) => {
            return {
                ...prevState,
                error,
                errorInfo,
            };
        });
    };

    private hideError = () => {
        this.setState((prevState) => {
            return {
                ...prevState,
                error: undefined,
                errorInfo: undefined,
            };
        });
    };
}

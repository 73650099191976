import React from 'react';
import { Estate } from './Estate';
import { CreateNewEstateCard } from './CreateNewEstateCard';
import { IEstate } from '../../models/Estate';
import './Listing.scss';
import { IUser } from '../../Models';
import { FilterTopbar, IFilters } from './FilterTopbar';
import { getUniqueDummyId } from '../../utils/math';

export interface IListingProps {
    currentUser: IUser;
    allUsers: ReadonlyArray<IUser>;
    estates: ReadonlyArray<IEstate>;
    onNew: (name: string) => void;
    onModify: (id: number) => void;
    onDelete: (id: number) => void;
    onRestore: (id: number) => void;
}

interface IListingState {
    readonly estates: ReadonlyArray<IEstate>;
    readonly filters: IFilters;
}

export class Listing extends React.Component<IListingProps, IListingState> {
    private orphanedId: number;
    private allId: number;

    public constructor(props: IListingProps) {
        super(props);

        this.state = {
            estates: [],
            filters: {
                user: props.currentUser,
                showActive: true,
                showRemoved: false,
            },
        };

        const getUniqueId = (extraVal?: number) => {
            const all = this.props.allUsers.map((u) => u.id);
            if (extraVal) {
                all.push(extraVal);
            }
            return getUniqueDummyId(all);
        };

        this.orphanedId = getUniqueId();
        this.allId = getUniqueId(this.orphanedId);
    }

    public render() {
        const estates = this.filter(
            this.props.estates,
            this.getCurrentFilters(),
        );

        return (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                {this.props.currentUser.isAdmin && (
                    <FilterTopbar
                        userGroups={this.getUserGroups()}
                        filters={this.state.filters}
                        filteringChanged={this.filteringChanged}
                    />
                )}
                <div className="list-view-container">
                    <CreateNewEstateCard onNew={this.props.onNew} />
                    {estates.map((e) => {
                        return (
                            <Estate
                                key={e.id}
                                thumbnailImage={
                                    e.thumbnail || 'default-page-thumbnail.png'
                                }
                                isRemoved={e.isRemoved}
                                purgeAt={e.purgeAt}
                                description={e.description}
                                onClick={() => this.props.onModify(e.id)}
                                export={() => {
                                    window.open(e.getPdfUrl());
                                }}
                                delete={() => this.props.onDelete(e.id)}
                                restore={() => this.props.onRestore(e.id)}
                            />
                        );
                    })}
                </div>
            </div>
        );
    }

    private getUserGroups() {
        const uniqueUsers = this.getUniqueUsers(
            this.props.estates,
            this.props.allUsers,
        );
        const userGroups = this.createUserGroups(
            this.props.currentUser,
            uniqueUsers,
        );
        return userGroups;
    }

    private getUniqueUsers(
        estates: ReadonlyArray<IEstate>,
        allUsers: ReadonlyArray<IUser>,
    ): IUser[] {
        const uniqueIds = new Set(estates.flatMap((e) => e.owners));
        return Array.from(uniqueIds).map(
            (i) => allUsers.filter((u) => u.id === i)[0],
        );
    }

    private createUserGroups(
        currentUser: IUser,
        allUsers: ReadonlyArray<IUser>,
    ): ReadonlyArray<ReadonlyArray<IUser>> {
        const dummyUser = (fullName: string, id: number) => {
            return {
                username: '',
                id,
                fullName,
                isAdmin: false,
            };
        };
        const others = allUsers.filter((u) => u.id !== currentUser.id);
        return [
            [currentUser],
            [
                dummyUser('Kaikki', this.allId),
                dummyUser('Orvot', this.orphanedId),
            ],
            others,
        ];
    }

    private getCurrentFilters() {
        const filters = this.state.filters;
        return {
            owner: filters.user.id,
            removed: filters.showRemoved,
            active: filters.showActive,
        };
    }

    private filteringChanged = (filters: IFilters) => {
        this.setState((prevState) => {
            return {
                ...prevState,
                filters,
            };
        });
    };

    public filter(
        estates: ReadonlyArray<IEstate>,
        filters: { owner: number; removed: boolean; active: boolean },
    ) {
        if (!filters.removed && !filters.active) {
            return [];
        }
        const res = this.filterByOwner(estates, filters.owner).filter(
            (e) =>
                e.isRemoved === filters.removed ||
                e.isRemoved !== filters.active,
        );

        // Show active estates first then removed.
        return res
            .filter((e) => !e.isRemoved)
            .concat(res.filter((e) => e.isRemoved));
    }

    private filterByOwner(estates: ReadonlyArray<IEstate>, owner: number) {
        if (owner === this.orphanedId) {
            return estates.filter((e) => e.owners.length === 0);
        } else if (owner === this.allId) {
            return [...estates];
        } else {
            return estates.filter((e) => e.owners.indexOf(owner) !== -1);
        }
    }
}

import React from 'react';
import './BucketFillIcon.scss';

interface IBucketFillIconProps {
    classModifier: string;
}

export const BucketFillIcon: React.SFC<IBucketFillIconProps> = (props) => {
    const svgClass = 'bucket-fill-icon' + ' ' + props.classModifier;

    return (
        <svg
            className={svgClass}
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 512 512"
            xmlSpace="preserve"
        >
            <path
                className="bucket-fill-icon-bucket-handle"
                d="M487,303.061c-3.699,0-6.873-2.796-7.265-6.556l-6.986-66.992c-0.702-6.73-4.93-12.099-11.307-14.361
c-6.381-2.263-13.043-0.754-17.829,
4.03l-16.588,
16.59c-2.857,
2.856-7.489,
2.857-10.343,
0c-2.857-2.856-2.857-7.488-0.001-10.343 l16.589-16.59c8.743-8.742,
21.412-11.605,
33.062-7.473c11.651,
4.132,
19.685,
14.335,
20.967,
26.63l6.986,
66.992 c0.419,
4.018-2.498,
7.615-6.516,
8.033C487.51,
303.048,
487.254,
303.061,
487,
303.061z"
            />
            <path
                className="bucket-fill-icon-text-background"
                d="M369.511,449.179H16.846C7.542,449.179,0,441.637,0,432.333V79.667
c0-9.304,
7.542-16.846,
16.846-16.846h352.666c9.304,
0,
16.846,
7.542,
16.846,
16.846v352.666 C386.358,
441.637,
378.815,
449.179,
369.511,
449.179z"
            />
            <g>
                <polygon
                    className="bucket-fill-icon-text"
                    points="340.287,108.893 340.287,108.892 223.466,108.892 162.892,108.892 46.071,108.892 
46.071,
169.466 46.072,
169.466 46.072,
204.08 106.645,
204.08 106.645,
169.466 162.892,
169.466 162.892,
342.534 125.484,
342.534 125.484,
403.108 162.892,
403.108 223.466,
403.108 260.873,
403.108 260.873,
342.534 223.466,
342.534 223.466,
169.466 279.713,
169.466 279.713,
204.08 340.287,
204.08 "
                />
                <polygon
                    className="bucket-fill-icon-bucket-empty"
                    points="322.204,294.174 290.573,325.804 367.672,339.643 426.98,398.95 511.987,313.943 
407.211,
209.167 "
                />
            </g>
            <polygon
                className="bucket-fill-icon-bucket-paint"
                points="512,313.945 426.98,398.95 367.682,339.651 290.574,325.813 322.208,294.178 
492.234,
294.178 "
            />
        </svg>
    );
};

import { FocusStyleManager } from '@blueprintjs/core';
FocusStyleManager.onlyShowFocusOnTabs();

import React, { Component } from 'react';
import {
    BrowserRouter as Router,
    Route,
    RouteComponentProps,
} from 'react-router-dom';
import './App.css';
import { Authenticator, IAuthData } from './Authenticator';
import { LoginForm } from './LoginForm';
import { IUser } from './Models';
import { TopNavbar } from './TopNavbar';
import 'filepond/dist/filepond.min.css';

import { IPrivateRouteProps, PrivateRoute } from './PrivateRoute';

import EstateList from './components/list_view/EstateList';
import { EstateController } from './components/EstateEditor';
import { ErrorBoundary } from './ErrorBoundary';
import { LoadingOverlay } from './components/common/LoadingOverlay';

type AuthStatus = 'unauthenticated' | 'loading' | 'authenticated';

interface IState {
    status: AuthStatus;
    user?: IUser;
}

class App extends Component<unknown, IState> {
    constructor(props: unknown) {
        super(props);

        this.state = {
            status: 'loading',
            user: undefined,
        };
    }

    public async componentDidMount() {
        const user = await Authenticator.autoLogin();
        const status = user ? 'authenticated' : 'unauthenticated';
        this.setState((prevState) => {
            return {
                ...prevState,
                status,
                user,
            };
        });
    }

    private handleLogin = async (x: IAuthData) => {
        const user = await Authenticator.handleLogin(x);
        const status = user ? 'authenticated' : 'unauthenticated';
        if (status !== this.state.status) {
            this.setState((prevState) => {
                return {
                    ...prevState,
                    status,
                    user,
                };
            });
        }
        return !!user;
    };

    private handleLogout = async () => {
        await Authenticator.handleLogout();
        this.setState((prevState) => {
            return {
                ...prevState,
                status: 'unauthenticated',
                user: undefined,
            };
        });
    };

    public render() {
        if (this.state.status === 'loading') {
            return <LoadingOverlay isOpen={true} />;
        }

        const defaultRouteProps: IPrivateRouteProps = {
            needsLogin: this.state.status === 'unauthenticated',
        };

        return (
            <Router>
                <ErrorBoundary>
                    <TopNavbar
                        user={this.state.user}
                        onLogoutClicked={this.handleLogout}
                    />
                    <div className="page-container">
                        <Route
                            exact
                            path="/login"
                            component={(props: RouteComponentProps<any>) => (
                                <LoginForm
                                    {...props}
                                    handleLogin={this.handleLogin}
                                    isAuthenticated={
                                        this.state.status === 'authenticated'
                                    }
                                />
                            )}
                        />
                        <PrivateRoute
                            {...defaultRouteProps}
                            exact={true}
                            path="/"
                            component={(props: any) => (
                                <EstateList {...props} user={this.state.user} />
                            )}
                        />
                        <PrivateRoute
                            {...defaultRouteProps}
                            exact={true}
                            path="/estate"
                            component={EstateController}
                        />
                    </div>
                </ErrorBoundary>
            </Router>
        );
    }
}

export default App;

export function removeItem<T>(
    arr: ReadonlyArray<T>,
    item: T,
): ReadonlyArray<T> {
    const idx = getIdx(arr, item);
    const cloned = [...arr];
    cloned.splice(idx, 1);
    return cloned;
}

export function moveItem<T>(
    arr: ReadonlyArray<T>,
    item: T,
    offset: number,
): ReadonlyArray<T> {
    const idx = getIdx(arr, item);
    const newIdx = idx + offset;
    if (newIdx >= 0 && newIdx < arr.length) {
        const cloned = [...arr];
        const toMove = cloned.splice(idx, 1)[0];
        cloned.splice(newIdx, 0, toMove);
        return cloned;
    } else {
        return arr;
    }
}

export function replace<T>(
    arr: ReadonlyArray<T>,
    oldItem: T,
    newItem: T,
): ReadonlyArray<T> {
    const idx = getIdx(arr, oldItem);
    const newArr = [...arr];
    newArr[idx] = newItem;
    return newArr;
}

function getIdx<T>(arr: ReadonlyArray<T>, item: T): number {
    const idx = arr.indexOf(item);
    if (idx === -1) {
        throw new Error(
            `Did not find item ${JSON.stringify(
                item,
            )} from array ${JSON.stringify(arr)}`,
        );
    }
    return idx;
}

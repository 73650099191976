import { IEstate } from '../models/Estate';
import { IPage, TemplateName } from '../models/Page';
import {
    ITemplateImage,
    ITemplateParameter,
} from '../models/TemplateParameter';
import { IImage } from '../models/Image';
import {
    IEstateImageJsonData,
    IEstateJsonData,
    IPageImageJsonData,
    IPageJsonData,
    IPageParameterJsonData,
} from './JsonDataObjects';

export function estateFromData(data: IEstateJsonData): IEstate {
    const images: ReadonlyArray<IImage> = data.images
        ? data.images.map(imageFromData)
        : [];
    return {
        id: data.id,
        isRemoved: !!data.is_removed, // using !! because might be undefined
        purgeAt: new Date(data.purge_at),
        owners: data.owners,
        description: data.name,
        thumbnail: data.thumbnail ? data.thumbnail : undefined,
        getPdfUrl: () => `/api/estate/${data.id}/render/`,
        images,
        pages: data.pages
            ? data.pages.map((page) =>
                  pageFromData(
                      new Map(
                          images.map((im) => <[number, IImage]>[im.id, im]),
                      ),
                      page,
                  ),
              )
            : [],
    };
}

export function pageFromData(
    images: Map<number, IImage>,
    data: IPageJsonData,
): IPage {
    return {
        id: data.id,
        estateId: data.estate,
        order: data.order,
        pageCount: data.page_count ? data.page_count : 1, // only PdfPage has page count - otherwise one
        templateName: templateNameFromData(data.template_name),
        parameters: data.parameters
            ? data.parameters.map((p) => paramFromData(images, p))
            : [],
        thumbnail: data.thumbnail,
        file: data.file,
    };
}

function templateNameFromData(name?: string): TemplateName {
    // note - if name === undefined, 'pdf' mapping is returned
    const mappings = templateNameMapping.filter((m) => m.serverName === name);
    if (mappings.length != 1) {
        throw new Error(
            `Unknown template_name ${name} in JSON data. Mappings count should be 1. Now was ${mappings.length}`,
        );
    }
    return mappings[0].uiName;
}

export function dataFromTemplateName(name: TemplateName) {
    return templateNameMapping.filter((m) => m.uiName === name)[0].serverName;
}

interface INameMapItem {
    uiName: TemplateName;
    serverName?: string;
}
const templateNameMapping: INameMapItem[] = [
    { uiName: 'pdf', serverName: undefined },
    { uiName: 'static1', serverName: 'static1.html' },
    { uiName: 'template1', serverName: 'template1.html' },
    { uiName: 'template2', serverName: 'template2.html' },
    { uiName: 'template3', serverName: 'template3.html' },
    { uiName: 'template4', serverName: 'template4.html' },
    { uiName: 'template5', serverName: 'template5.html' },
    { uiName: 'template6', serverName: 'template6.html' },
    { uiName: 'template7', serverName: 'template7.html' },
    { uiName: 'template8', serverName: 'template8.html' },
    { uiName: 'template9', serverName: 'template9.html' },
    { uiName: 'template10', serverName: 'template10.html' },
];

function paramFromData(
    images: Map<number, IImage>,
    data: IPageParameterJsonData,
): ITemplateParameter {
    const original = data.image && images.get(data.image.original_image);
    if (!data.id) {
        throw new Error('There should always be id in IPageParameterJsonData!');
    }
    return {
        id: data.id,
        pageId: data.page,
        slotName: data.slot_name,
        templateText: data.text
            ? {
                  text: data.text.text,
                  opacity: data.text.opacity,
                  invertColors: !!data.text.invert_colors,
              }
            : undefined,
        templateImage:
            data.image && original
                ? {
                      image: original,
                      x: data.image.x,
                      y: data.image.y,
                      width: data.image.width,
                      height: data.image.height,
                  }
                : undefined,
        templateIcons: (data.icons || []).map((icondata: any) => ({
            url: icondata.icon,
            x: icondata.x,
            y: icondata.y,
            width: icondata.width,
        })),
    };
}

export function imageFromData(data: IEstateImageJsonData): IImage {
    function filename(url: string): string {
        const match = /[^/]+\.(jpg|jpeg)/.exec(url);
        return (match && match[0]) || 'kuva';
    }
    return {
        id: data.id,
        name: filename(data.file),
        url: data.url ? data.url : '', // TODO WTF? Why url is not defined to Json always?
        mediumUrl: data.file_medium,
        thumbnailUrl: data.file_thumbnail,
    };
}

export function dataFromEstate(estate: IEstate) {
    return {
        id: estate.id,
        name: estate.description,
    };
}

export function dataFromParameter(
    param: ITemplateParameter,
): IPageParameterJsonData {
    return {
        page: param.pageId,
        slot_name: param.slotName,
        text: param.templateText
            ? {
                  text: param.templateText.text,
                  opacity: param.templateText.opacity,
                  invert_colors: !!param.templateText.invertColors, // TODO using here !! because without backend changes this might be undefined
              }
            : undefined,
        image: param.templateImage
            ? dataFromTemplateImage(param.templateImage)
            : undefined,
        icons: param.templateIcons.map((icon) => ({
            icon: icon.url,
            x: icon.x,
            y: icon.y,
            width: icon.width,
        })),
    };
}

function dataFromTemplateImage(im: ITemplateImage): IPageImageJsonData {
    return {
        original_image: im.image.id!,
        x: im.x,
        y: im.y,
        width: im.width,
        height: im.height,
    };
}

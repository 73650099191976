import React from 'react';

import { Listing } from './Listing';

import { RouteComponentProps } from 'react-router';
import Routes from '../../routes';

import qs from 'query-string';
import { EstateClient } from '../../api/EstateClient';
import { UserClient } from '../../api/UserClient';
import * as PageClient from '../../api/PageClient';
import { IEstate } from '../../models/Estate';
import { LoadingOverlay } from '../common/LoadingOverlay';
import { getTemplateParameterSlotNames } from '../../templates/TemplateMapper';
import { IUser } from '../../Models';

interface IEstateListProps extends RouteComponentProps {
    readonly user: IUser;
}

interface IEstateListState {
    estates: ReadonlyArray<IEstate>;
    users: ReadonlyArray<IUser>;
    loading: boolean;
}

export default class EstateList extends React.Component<
    IEstateListProps,
    IEstateListState
> {
    public state = { estates: [], users: [], loading: true };

    public async componentDidMount() {
        this.setLoading(true);
        await this.reload();
        this.setLoading(false);
    }

    public render() {
        const user = this.props.user;
        if (!user) {
            throw new Error('Missing user, cannot render anything');
        }
        return (
            <div>
                <Listing
                    currentUser={user}
                    allUsers={this.state.users}
                    estates={this.state.estates}
                    onNew={this.handleNew}
                    onModify={this.handleModify}
                    onDelete={this.handleDelete}
                    onRestore={this.handleRestore}
                />
                <LoadingOverlay isOpen={this.state.loading} />
            </div>
        );
    }

    private handleNew = async (name: string) => {
        this.setLoading(true);
        const estate = await EstateClient.create(name);
        const slotNames = getTemplateParameterSlotNames('template1');
        await PageClient.createPage(estate, 'template1', slotNames);
        this.setLoading(false);
        this.handleModify(estate.id);
    };

    private handleModify = (id: number) => {
        const query = { id };
        this.props.history.push({
            pathname: Routes.Estate,
            search: qs.stringify(query),
        });
    };

    private handleDelete = async (id: number) => {
        this.setLoading(true);
        await EstateClient.delete(id);
        await this.reload();
        this.setLoading(false);
    };

    private handleRestore = async (id: number) => {
        this.setLoading(true);
        await EstateClient.restore(id);
        await this.reload();
        this.setLoading(false);
    };

    private async reload() {
        const estates = await EstateClient.getEstates();
        const users = await UserClient.getUsers();

        this.setState((prevState) => {
            return {
                ...prevState,
                estates,
                users,
            };
        });
    }

    private setLoading(isLoading: boolean) {
        this.setState((prevState) => {
            return {
                ...prevState,
                loading: isLoading,
            };
        });
    }
}

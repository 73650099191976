import React from 'react';
import { container, sheet } from './styling';
import { IPdfProps } from './TemplateProps';

export const Pdf = (props: IPdfProps) => {
    return (
        <div style={sheet}>
            <div style={container}>
                <embed
                    key={props.file}
                    src={props.file}
                    width="760px"
                    height="1080px"
                />
            </div>
        </div>
    );
};

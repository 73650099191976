import { FilePondProps } from 'react-filepond';
import { Authenticator } from '../Authenticator';

export function getProps(estateId: number): FilePondProps {
    return {
        labelIdle:
            // tslint:disable-next-line:max-line-length
            'Raahaa ja pudota kuva- tai PDF-tiedostoja tähän tai <span class="filepond--label-action">selaa tästä tiedostoja</span> tietokoneeltasi.',
        labelFileLoading: 'Ladataan',
        labelFileProcessing: 'Lähetetään',
        labelFileProcessingComplete: 'Lähetys valmis',
        labelFileProcessingAborted: 'Lähetys keskeytetty',
        labelFileProcessingError: 'Virhe lähetyksessä',
        labelTapToCancel: '',
        allowMultiple: true,
        allowReplace: false,
        allowRevert: false,
        server: {
            process: {
                url: '/api/image/process/',
                ondata: (formData: FormData) => {
                    formData.append('estate', estateId.toString());
                    return formData;
                },
                headers: {
                    'X-CSRFToken': Authenticator.csrfToken(),
                },
                method: 'POST',
            },
            revert: {
                url: '/api/image/revert/',
                method: 'DELETE',
                headers: {
                    'X-CSRFToken': Authenticator.csrfToken(),
                    'Content-Type': 'application/json',
                },
            },
            fetch: '',
            restore: '',
            load: '',
        },
    };
}

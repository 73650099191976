import { Authenticator } from '../Authenticator';

type Method = 'GET' | 'POST' | 'PUT' | 'DELETE';

function jsonRequest(
    url: string,
    method: Method,
    data: any,
): Promise<Response> {
    const txData = data ? JSON.stringify(data) : null;
    return request(
        url,
        method,
        {
            'Content-Type': 'application/json',
        },
        txData,
    );
}

let lastRequest: Promise<any> = Promise.resolve(null);

function request(url: string, method: Method, extraHeaders: any, body: any) {
    const data = {
        method,
        headers: {
            ...extraHeaders,
            'X-CSRFToken': Authenticator.csrfToken(),
        },
        body,
    };

    const currentRequest = lastRequest.then(
        () =>
            new Promise<Response>((resolve, reject) => {
                return fetch(url, data).then((response) => {
                    const doReject = (respBody?: any, reason?: any) => {
                        reject({
                            url,
                            method,
                            data,
                            status: response.status,
                            statusText: response.statusText,
                            headers: response.headers,
                            body: respBody,
                            reason,
                        });
                    };
                    if (!response.ok) {
                        // Need to clone the response to try read as text stream if json reading fails.
                        // The body gets used and cannot be re-used.
                        const textResponse = response.clone();
                        response
                            .json()
                            .then((respBody) => doReject(respBody, undefined))
                            .catch(() => {
                                textResponse
                                    .text()
                                    .then((text) => doReject(text, undefined))
                                    .catch((reason) =>
                                        doReject(undefined, reason),
                                    );
                            });
                    } else {
                        return resolve(response);
                    }
                });
            }),
    );

    lastRequest = currentRequest;
    return currentRequest;
}

export const Api = {
    get: (url: string) => jsonRequest(url, 'GET', undefined),
    post: (url: string, data: any) => jsonRequest(url, 'POST', data),
    put: (url: string, data: any) => jsonRequest(url, 'PUT', data),
    delete: (url: string) => jsonRequest(url, 'DELETE', undefined),
    postFormData: (url: string, form: FormData) => {
        return request(url, 'POST', {}, form);
    },
    lastAction: () => lastRequest,
};

import { IUser } from './Models';
import { userFromData } from './api/UserClient';

export interface IAuthData {
    username: string;
    password: string;
}

class AuthenticatorService {
    public async autoLogin(): Promise<IUser | undefined> {
        return await this.receiveLoginData(
            fetch('/api/user/login/', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            }),
        );
    }

    public async handleLogin(data: IAuthData): Promise<IUser | undefined> {
        const request = fetch('/api/user/login/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': Authenticator.csrfToken(),
            },
            body: JSON.stringify(data),
        });
        return await this.receiveLoginData(request);
    }

    public async handleLogout(): Promise<void> {
        await fetch('/api/user/logout/', {
            method: 'POST',
            headers: {
                'X-CSRFToken': Authenticator.csrfToken(),
            },
        });
    }

    private async receiveLoginData(
        request: Promise<Response>,
    ): Promise<IUser | undefined> {
        try {
            const response = await request;
            const json: any = await response.json();
            if (response.status >= 400 || !json.id) {
                throw new Error(await response.text());
            }
            return userFromData(json);
        } catch (e) {
            return undefined;
        }
    }

    public csrfToken(): string {
        function getCookieValue(a: string): string {
            const b = document.cookie.match(
                '(^|[^;]+)\\s*' + a + '\\s*=\\s*([^;]+)',
            );
            return b ? b.pop() || '' : '';
        }

        return getCookieValue('csrftoken');
    }
}

export const Authenticator = new AuthenticatorService();

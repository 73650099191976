import {
    Card,
    Elevation,
    Popover,
    Button,
    Intent,
    H5,
    PopoverPosition,
    Classes,
} from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import React from 'react';
import { AutoHoverIcon } from '../common/Icons';
import { IPage } from '../../models/Page';
import { getPageCountInfo } from './EstateHelpers';

export interface ISideBarProps {
    pages: ReadonlyArray<IPage>;
    onSelectPage: (page: IPage) => void;
    onAddNewPage: () => void;
    onMoveUp: (page: IPage) => void;
    onMoveDown: (page: IPage) => void;
    onDeletePage: (page: IPage) => void;
}

export const SideBar = (props: ISideBarProps) => {
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                maxHeight: '100vh',
            }}
        >
            {props.pages.map((page) => {
                return (
                    <div key={page.id}>
                        {createThumbnail(props.pages, page, props)}
                    </div>
                );
            })}

            <div style={{ alignSelf: 'center ', paddingTop: '20px' }}>
                <AutoHoverIcon
                    icon={IconNames.ADD}
                    tooltip={'Lisää uusi sivu'}
                    iconSize={60}
                    onClick={props.onAddNewPage}
                />
            </div>
        </div>
    );
};

function createThumbnail(
    allPages: ReadonlyArray<IPage>,
    page: IPage,
    props: ISideBarProps,
) {
    const { pageNumberText } = getPageCountInfo(allPages, page);

    const thumbnail = page.thumbnail
        ? page.thumbnail
        : page.templateName === 'pdf'
        ? page.pageCount > 1
            ? 'pdfs_thumbnail.png'
            : 'pdf_thumbnail.png'
        : 'image_missing.png';

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'row',
                marginBottom: '10px',
            }}
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-evenly',
                    padding: '10px',
                    position: 'relative',
                }}
            >
                <AutoHoverIcon
                    icon={IconNames.CIRCLE_ARROW_UP}
                    tooltip={'Siirrä ylös'}
                    onClick={() => props.onMoveUp(page)}
                />
                <Popover
                    position={PopoverPosition.RIGHT}
                    minimal={true}
                    content={confirmDelete(() => props.onDeletePage(page))}
                >
                    <AutoHoverIcon icon={IconNames.DELETE} tooltip={'Poista'} />
                </Popover>
                <AutoHoverIcon
                    icon={IconNames.CIRCLE_ARROW_DOWN}
                    tooltip={'Siirrä alas'}
                    onClick={() => props.onMoveDown(page)}
                />
            </div>
            <div>
                <Card
                    elevation={Elevation.ZERO}
                    interactive={true}
                    style={{
                        margin: '10px',
                        padding: '0',
                        borderRadius: '0',
                        width: '150px',
                        height: '204px',
                    }}
                >
                    <img
                        style={{ padding: '5px' }}
                        src={thumbnail}
                        width="100%"
                        height="100%"
                        onClick={() => props.onSelectPage(page)}
                    />
                </Card>

                <div style={{ margin: 'auto', textAlign: 'center' }}>
                    {pageNumberText}
                </div>
            </div>
        </div>
    );
}

function confirmDelete(onClick: any) {
    return (
        <div style={{ padding: 10 }}>
            <H5>Sivun poisto</H5>
            <p>Haluatko varmasti poistaa sivun? Tätä toimintoa ei voi perua.</p>
            <Button
                className={Classes.POPOVER_DISMISS}
                style={{ marginRight: 10 }}
            >
                Peruuta
            </Button>
            <Button intent={Intent.DANGER} onClick={onClick}>
                Poista
            </Button>
        </div>
    );
}

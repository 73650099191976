import React from 'react';
import { container, sheet } from './styling';
import { IPageWithTwoImages } from './TemplateProps';
import { TemplateImage } from './TemplateImage';

export const Template5 = (props: IPageWithTwoImages) => {
    return (
        <div style={sheet}>
            <div style={container}>
                <TemplateImage img={props.img1} style={topSection} />
                <TemplateImage img={props.img2} style={bottomSection} />
            </div>
        </div>
    );
};

const topSection = {
    display: 'flex',
    overflow: 'hidden',
    width: '100%',
    height: '50%',
    marginBottom: '0.5cm',
    position: 'relative' as const,
};

const bottomSection = {
    display: 'flex',
    overflow: 'hidden',
    width: '100%',
    height: '50%',
    position: 'relative' as const,
};

import { IUser } from '../Models';
import { Api } from './Api';

class UserClient {
    public async getUsers(): Promise<ReadonlyArray<IUser>> {
        const response = await Api.get('/api/user/');
        const data: any = await response.json();
        return data.map(userFromData);
    }
}

export function userFromData(data: any): IUser {
    const getFullName = () => {
        if (data.first_name === '' && data.last_name === '') {
            return undefined;
        }
        return `${data.first_name} ${data.last_name}`;
    };
    return {
        id: data.id,
        username: data.username,
        fullName: getFullName(),
        isAdmin: data.is_superuser,
    };
}

const client = new UserClient();

export { client as UserClient };

import React, { MouseEventHandler } from 'react';
import { IUser } from '../../Models';
import { Button, MenuItem } from '@blueprintjs/core';
import { Select, IItemModifiers } from '@blueprintjs/select';
import { IconNames } from '@blueprintjs/icons';
import { getUniqueDummyId } from '../../utils/math';

interface IUserSelect {
    text: string;
    label: string;
    value: number;
    disabled: boolean;
    user?: IUser;
}

const ItemSelect = Select.ofType<IUserSelect>();

export const UserSelect = (props: {
    className: string;
    selected: IUser;
    userGroups: ReadonlyArray<ReadonlyArray<IUser>>;
    onChange: (user: IUser) => void;
}) => {
    const onItemSelect = (item: IUserSelect) => {
        if (item.user) {
            props.onChange(item.user);
        }
    };
    const options = mapToUserSelect(props.userGroups);
    return (
        <div>
            <ItemSelect
                className={props.className}
                items={options}
                itemRenderer={renderItem}
                itemPredicate={filterItem}
                onItemSelect={onItemSelect}
                popoverProps={{ minimal: true }}
            >
                <Button
                    icon={IconNames.USER}
                    rightIcon={IconNames.CARET_DOWN}
                    text={userText(props.selected)}
                    disabled={false}
                />
            </ItemSelect>
        </div>
    );
};

function mapToUserSelect(
    userGroups: ReadonlyArray<ReadonlyArray<IUser>>,
): IUserSelect[] {
    const uniqueIds: number[] = [];
    const result: IUserSelect[] = [];
    for (const users of userGroups) {
        const labels = users.map((u) => {
            return {
                label: u.username,
                text: userText(u),
                value: u.id,
                disabled: false,
                user: u,
            };
        });
        const uniqueDummyId = getUniqueDummyId(
            userGroups
                .flat()
                .map((u) => u.id)
                .concat(uniqueIds),
        );
        uniqueIds.push(uniqueDummyId);
        const sortedLabels = labels.sort((a, b) => {
            return a.label.toLowerCase().localeCompare(b.label.toLowerCase());
        });
        result.push(...sortedLabels);
        result.push({
            text: '─────',
            label: '',
            value: uniqueDummyId,
            disabled: true,
            user: undefined,
        });
    }
    return result.slice(0, -1);
}

function userText(user: IUser) {
    return user.fullName ? user.fullName : user.username;
}

function renderItem(
    item: IUserSelect,
    props: {
        handleClick: MouseEventHandler<HTMLElement>;
        modifiers: IItemModifiers;
        query: string;
    },
) {
    if (!item.user) {
        return (
            <MenuItem
                active={false}
                disabled={true}
                label={item.label}
                key={item.value}
                text={'─────'}
            />
        );
    }
    return (
        <MenuItem
            active={props.modifiers.active}
            disabled={item.disabled}
            label={item.label}
            key={item.value}
            onClick={props.handleClick}
            text={highlightText(item.text, props.query)}
        />
    );
}

function highlightText(text: string, query: string) {
    let lastIndex = 0;
    const words = query
        .split(/\s+/)
        .filter((word) => word.length > 0)
        .map(escapeRegExpChars);
    if (words.length === 0) {
        return [text];
    }
    const regexp = new RegExp(words.join('|'), 'gi');
    const tokens: React.ReactNode[] = [];
    while (true) {
        const match = regexp.exec(text);
        if (!match) {
            break;
        }
        const length = match[0].length;
        const before = text.slice(lastIndex, regexp.lastIndex - length);
        if (before.length > 0) {
            tokens.push(before);
        }
        lastIndex = regexp.lastIndex;
        tokens.push(<strong key={lastIndex}>{match[0]}</strong>);
    }
    const rest = text.slice(lastIndex);
    if (rest.length > 0) {
        tokens.push(rest);
    }
    return tokens;
}

function escapeRegExpChars(text: string): string {
    return text.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, '\\$1');
}

function filterItem(query: string, item: IUserSelect): boolean {
    const normalizedTitle = item.text.toLowerCase();
    const normalizedQuery = query.toLowerCase();
    const normalizedLabel = item.label.toLowerCase();
    return (
        `${normalizedTitle} ${normalizedLabel}`.indexOf(normalizedQuery) !== -1
    );
}

import { ITemplateImage } from '../models/TemplateParameter';
import { CSSProperties } from 'react';
import { IDimensions } from '../models/Dimensions';

export async function croppingToStyle(
    divDimensions: IDimensions, // Target dimensions to fit the image to
    templateImage: ITemplateImage, // Image with cropping to be fitted to given div dimensions
): Promise<CSSProperties> {
    const imageDimensions = await getImageDimensions(
        templateImage.image.mediumUrl,
    );
    const croppedWidth = templateImage.width * imageDimensions.width;
    const croppedHeight = templateImage.height * imageDimensions.height;

    const widthFactor = divDimensions.width / croppedWidth;
    const heightFactor = divDimensions.height / croppedHeight;

    const width = imageDimensions.width * widthFactor;
    const height = imageDimensions.height * heightFactor;

    const offsetX = templateImage.x * imageDimensions.width * widthFactor;
    const offsetY = templateImage.y * imageDimensions.height * heightFactor;

    return {
        marginLeft: -offsetX + 'px',
        marginTop: -offsetY + 'px',
        width: width + 'px',
        height: height + 'px',
    };
}

function getImageDimensions(url: string): Promise<IDimensions> {
    return new Promise((resolve) => {
        const img = new Image();
        let width: number;
        let height: number;
        img.onload = () => {
            width = img.width;
            height = img.height;
            resolve({ width, height });
        };
        img.src = url;
    });
}

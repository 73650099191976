import { Api } from './Api';
import { IEstate } from '../models/Estate';
import { TemplateName, IPage } from '../models/Page';
import { ITemplateParameter } from '../models/TemplateParameter';
import {
    pageFromData,
    dataFromParameter,
    dataFromTemplateName,

} from './Mapper';
import {IPageJsonData} from './JsonDataObjects';

export async function createPage(
    estate: IEstate,
    template: TemplateName,
    parameterSlotNames: string[],
): Promise<IPage> {
    const order = newPageOrder(estate);
    const data = {
        estate: estate.id,
        template_name: dataFromTemplateName(template),
        order,
        parameter_names: parameterSlotNames,
    };
    const response = await Api.post('/api/templatepage/', data);
    const responseData = await response.json();
    // TODO - maybe some day json validation would be awesome
    return pageFromData(new Map(), responseData as IPageJsonData);
}

export async function createPdfPage(estate: IEstate, file: File) {
    const order = newPageOrder(estate);
    const form = new FormData();
    form.append('file', file);
    form.append('enctype', 'multipart/form-data');
    form.append('estate', estate.id.toString());
    form.append('order', order.toString());
    const response = await Api.postFormData('/api/pdfpage/', form);
    return pageFromData(new Map(), await response.json());
}

function newPageOrder(estate: IEstate) {
    return estate.pages.length > 0
        ? Math.max(...estate.pages.map((p) => p.order)) + 1
        : 1;
}

export async function deletePage(page: IPage): Promise<void> {
    await Api.delete(`/api/templatepage/${page.id}/`);
}

export async function deletePdfPage(page: IPage): Promise<void> {
    await Api.delete(`/api/pdfpage/${page.id}/`);
}

export async function updateParameter(
    parameter: ITemplateParameter,
): Promise<void> {
    await Api.put(
        `/api/templateparameter/${parameter.id}/`,
        dataFromParameter(parameter),
    );
}

export async function getPageThumbnail(
    id: number,
): Promise<string | undefined> {
    const response = await Api.get(`/api/templatepage/${id}/`);
    const data: any = await response.json();
    return data.thumbnail;
}

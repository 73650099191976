import React from 'react';
import { Classes, Spinner, Overlay } from '@blueprintjs/core';
import './LoadingOverlay.scss';

export const LoadingOverlay = (props: { isOpen: boolean }) => {
    return (
        <Overlay
            className={Classes.OVERLAY_SCROLL_CONTAINER + ' loading-overlay'}
            backdropClassName="loading-overlay-backdrop"
            isOpen={props.isOpen}
            canEscapeKeyClose={false}
            canOutsideClickClose={false}
            enforceFocus={true}
            hasBackdrop={true}
            transitionDuration={300}
            transitionName="loading-overlay-transition"
        >
            <div className="loading-overlay-spinner-container">
                <Spinner className="loading-overlay-spinner" size={100} />
            </div>
        </Overlay>
    );
};

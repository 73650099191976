import React from 'react';
import './InvertColorsIcon.scss';

export interface IInvertColorsIcon {
    classModifier?: string;
}

export const InvertColorsIcon: React.FC<IInvertColorsIcon> = (props) => {
    const svgClass =
        'invert-colors-icon' +
        (props.classModifier ? ' ' + props.classModifier : '');

    return (
        <svg
            className={svgClass}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 475.0628 475.0628"
        >
            <rect
                className="invert-colors-icon-rectangle"
                x="20"
                y="20"
                width="435.0628"
                height="435.0628"
            />
            <polygon
                className="invert-colors-icon-text"
                points="384.618 87.206 384.618 87.205 267.797 87.205 207.223 87.205 90.402 87.205 90.402 147.779 90.403 147.779 90.403 182.393 150.976 182.393 150.976 147.779 207.223 147.779 207.223 320.847 169.815 320.847 169.815 381.421 207.223 381.421 267.797 381.421 305.204 381.421 305.204 320.847 267.797 320.847 267.797 147.779 324.044 147.779 324.044 182.393 384.618 182.393 384.618 87.206"
            />
        </svg>
    );
};

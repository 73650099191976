import React from 'react';
import { DraftBlockType } from 'draft-js';
import { OrderedSet } from 'immutable';
import {
    Button,
    ButtonGroup,
    Tooltip,
    IconName,
    Position,
    PopoverPosition,
} from '@blueprintjs/core';
import './TextEditorControls.scss';
import { BucketFillIcon } from './BucketFillIcon';
import { InvertColorsIcon } from './InvertColorsIcon';

export type Alignment = 'left' | 'center' | 'right';

const BLOCKS = [
    {
        label: 'H1',
        tooltip: 'Otsikko 1',
        style: 'header-one' as DraftBlockType,
    },
    {
        label: 'H2',
        tooltip: 'Otsikko 2',
        style: 'header-two' as DraftBlockType,
    },
    {
        label: 'H3',
        tooltip: 'Otsikko 3',
        style: 'header-three' as DraftBlockType,
    },
];

const STYLES = [
    { icon: 'bold' as IconName, tooltip: 'Lihavoi', style: 'BOLD' },
];

const ALIGNMENTS: ReadonlyArray<{
    icon: IconName;
    tooltip: string;
    alignment: Alignment;
}> = [
    {
        icon: 'align-left',
        tooltip: 'Tasaa vasemmalle',
        alignment: 'left',
    },
    {
        icon: 'align-center',
        tooltip: 'Tasaa keskelle',
        alignment: 'center',
    },
    {
        icon: 'align-right',
        tooltip: 'Tasaa oikealle',
        alignment: 'right',
    },
];

const OPACITIES: ReadonlyArray<{
    opacity: number;
    tooltip: string;
    classModifier: string;
}> = [
    {
        opacity: 0,
        tooltip: 'Läpinäkyvä tausta',
        classModifier: 'mod-alpha-00',
    },
    {
        opacity: 0.7,
        tooltip: 'Läpikuultava tausta',
        classModifier: 'mod-alpha-05',
    },
    {
        opacity: 1.0,
        tooltip: 'Valkoinen tausta',
        classModifier: 'mod-alpha-10',
    },
];

function preventEventBubbling(
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
) {
    event.preventDefault();
}

const LabelButton = (props: {
    isActive: boolean;
    label: string;
    tooltip: string;
    onToggle: () => void;
}) => {
    let className = 'text-editor-style-button';
    if (props.isActive) {
        className += ' mod-active';
    }
    return (
        <Tooltip content={props.tooltip} position={'top-left'}>
            <div onMouseDown={preventEventBubbling}>
                <Button className={className} onClick={props.onToggle}>
                    {props.label}
                </Button>
            </div>
        </Tooltip>
    );
};

const IconButton = (props: {
    icon: IconName;
    tooltip: string;
    isActive: boolean;
    onToggle: () => void;
}) => {
    let className = 'text-editor-style-button';
    if (props.isActive) {
        className += ' mod-active';
    }
    return (
        <Tooltip content={props.tooltip} position={Position.TOP_LEFT}>
            <div onMouseDown={preventEventBubbling}>
                <Button
                    className={className}
                    icon={props.icon}
                    onMouseDown={props.onToggle}
                />
            </div>
        </Tooltip>
    );
};

type OpacityCallback = (opacity: number) => void;

const OpacityIconButton = (props: {
    opacity: number;
    tooltip: string;
    classModifier: string;
    opacityCallback: OpacityCallback;
    isActive: boolean;
    popoverPosition?: PopoverPosition;
}) => {
    const pp = props.popoverPosition
        ? props.popoverPosition
        : PopoverPosition.TOP_LEFT;
    let iconClassName = props.classModifier;
    if (props.isActive) {
        iconClassName += ' mod-active';
    }
    return (
        <Tooltip position={pp} content={props.tooltip}>
            <div onMouseDown={preventEventBubbling}>
                <Button
                    className="text-editor-style-button mod-fill"
                    onMouseDown={() => props.opacityCallback(props.opacity)}
                >
                    <BucketFillIcon classModifier={iconClassName} />
                </Button>
            </div>
        </Tooltip>
    );
};

const InvertColorsButton = (props: {
    invertCallback: () => void;
    isActive: boolean;
    popoverPosition?: PopoverPosition;
}) => {
    const pp = props.popoverPosition
        ? props.popoverPosition
        : PopoverPosition.TOP_LEFT;
    let iconClassName = '';
    if (props.isActive) {
        iconClassName += ' mod-active';
    }
    return (
        <Tooltip position={pp} content="Käännä värit">
            <div onMouseDown={preventEventBubbling}>
                <Button
                    className="text-editor-style-button mod-fill"
                    onMouseDown={() => props.invertCallback()}
                >
                    <InvertColorsIcon classModifier={iconClassName} />
                </Button>
            </div>
        </Tooltip>
    );
};

interface ITextEditorControlsProps {
    currentBlock: DraftBlockType;
    currentAlignment: Alignment;
    currentStyle: OrderedSet<string>;
    currentOpacity: number;
    currentInvertColors: boolean;
    changeBlockType: (type: DraftBlockType) => void;
    changeAlignment: (alignment: 'left' | 'center' | 'right') => void;
    changeStyle: (style: string) => void;
    changeBackgroundOpacity?: (opacity: number) => void;
    changeInvertColors?: () => void;
}

export const TextEditorControls = (props: ITextEditorControlsProps) => {
    return (
        <div className="text-editor-controls">
            <ButtonGroup>
                {BLOCKS.map((b, i) => {
                    return (
                        <LabelButton
                            key={i}
                            isActive={b.style === props.currentBlock}
                            label={b.label}
                            tooltip={b.tooltip}
                            onToggle={() => props.changeBlockType(b.style)}
                        />
                    );
                })}
            </ButtonGroup>
            <ButtonGroup>
                {STYLES.map((s, i) => {
                    return (
                        <IconButton
                            key={i}
                            icon={s.icon}
                            isActive={props.currentStyle.has(s.style)}
                            tooltip={s.tooltip}
                            onToggle={() => props.changeStyle(s.style)}
                        />
                    );
                })}
            </ButtonGroup>
            <ButtonGroup>
                {ALIGNMENTS.map((a, i) => {
                    return (
                        <IconButton
                            key={i}
                            icon={a.icon}
                            isActive={a.alignment === props.currentAlignment}
                            tooltip={a.tooltip}
                            onToggle={() => props.changeAlignment(a.alignment)}
                        />
                    );
                })}
            </ButtonGroup>
            {props.changeBackgroundOpacity && (
                <React.Fragment>
                    <ButtonGroup>
                        {OPACITIES.map((o, i) => {
                            return (
                                <OpacityIconButton
                                    key={i}
                                    opacity={o.opacity}
                                    tooltip={o.tooltip}
                                    classModifier={o.classModifier}
                                    opacityCallback={
                                        props.changeBackgroundOpacity as OpacityCallback
                                    }
                                    isActive={
                                        props.currentOpacity === o.opacity
                                    }
                                />
                            );
                        })}
                    </ButtonGroup>
                    {props.changeInvertColors && (
                        <ButtonGroup>
                            <InvertColorsButton
                                isActive={props.currentInvertColors}
                                invertCallback={props.changeInvertColors}
                            />
                        </ButtonGroup>
                    )}
                </React.Fragment>
            )}
        </div>
    );
};

import * as React from 'react';
import { Redirect, Route, RouteProps } from 'react-router';

export interface IPrivateRouteProps extends RouteProps {
    needsLogin: boolean;
}

export class PrivateRoute extends Route<IPrivateRouteProps> {
    public render() {
        let redirectPath = '';
        if (this.props.needsLogin) {
            redirectPath = '/login';
        }

        if (redirectPath) {
            const renderComponent = () => (
                <Redirect
                    to={{
                        pathname: redirectPath,
                        state: { from: this.props.location },
                    }}
                />
            );
            return <Route {...this.props} component={renderComponent} />;
        } else {
            return <Route {...this.props} />;
        }
    }
}

import { IPage } from '../../models/Page';

interface IPageCountInfo {
    activePageStart: number;
    activePageEnd: number;
    totalPageCount: number;
    pageNumberText: string;
}

export function getTotalPageCount(pages: ReadonlyArray<IPage>) {
    return pages.map((p) => p.pageCount).reduce((a, b) => a + b, 0);
}
export function getPageCountInfo(
    allPages: ReadonlyArray<IPage>,
    activePage: IPage,
): IPageCountInfo {
    const index = allPages.indexOf(activePage);
    const activePageStart = allPages.reduce(
        (acc, cur, i) => acc + (i < index ? cur.pageCount : 0),
        1,
    );
    const activePageEnd = activePageStart + (activePage.pageCount - 1);
    const pageNumberText =
        activePageStart === activePageEnd
            ? `${activePageStart}`
            : `${activePageStart}-${activePageEnd}`;

    const totalPageCount = getTotalPageCount(allPages);

    return {
        activePageStart,
        activePageEnd,
        totalPageCount,
        pageNumberText,
    };
}

import {
    Button,
    ButtonGroup,
    Classes,
    Divider,
    H5,
    Intent,
    Popover,
    PopoverPosition,
} from '@blueprintjs/core';
import React from 'react';
import { IEstate } from '../../models/Estate';
import { IPage, TemplateName } from '../../models/Page';
import { TemplateSelector } from './TemplateSelector';
import { SideBar } from './SideBar';
import { ImageSelector } from './ImageSelector';
import { IImage } from '../../models/Image';
import { Description } from './Description';
import { PageEdit } from './PageEdit';
import { ImageCropper } from './ImageCropper';
import { ITemplateIcon, ITemplateImage } from '../../models/TemplateParameter';
import { LoadingOverlay } from '../common/LoadingOverlay';
import { IconPlacer } from './IconPlacer';
import { IDimensions } from '../../models/Dimensions';
import { Api } from '../../api/Api';
import { getPageCountInfo, getTotalPageCount } from './EstateHelpers';

interface IEstateState {
    activePageId?: number;
    templateSelectorOpen: boolean;
    imageSelectorOpen: boolean;
    imageSelectorInitialOpen: boolean;
    imageCropperOpen: boolean;
    overlayOpen: boolean;
    activeSlot?: string;
    imageToCrop?: ITemplateImage;
    imageToCropAspectRatio?: number;
    iconPlacer?: {
        isOpen: boolean;
        image: ITemplateImage;
        slotSize: IDimensions;
    };
}

interface IEstateProps {
    estate: IEstate;
    imagesChanged: () => void;
    addNewPage: (
        selected: TemplateName,
        file?: File,
    ) => Promise<number | undefined>;
    changeDescription: (description: string) => void;
    imageCropped: (
        croppedImage: ITemplateImage,
        pageId: number,
        slotName: string,
    ) => void;
    iconsChanged: (
        icons: ITemplateIcon[],
        pageId: number,
        slotName: string,
    ) => void;
    changeSlotText: (text: string, pageId: number, slotName: string) => void;
    changeSlotOpacity: (
        opacity: number,
        pageId: number,
        slotName: string,
    ) => void;
    toggleSlotInvertColors: (pageId: number, slotName: string) => void;
    deletePage: (page: IPage) => void;
    reorder: (page: IPage, direction: 'up' | 'down') => void;
}

type Act = 'open' | 'close';

export class Estate extends React.Component<IEstateProps, IEstateState> {
    public static defaultProps = {
        creatingNew: false,
    };

    public constructor(props: IEstateProps) {
        super(props);

        const activePageId =
            props.estate.pages.length > 0
                ? props.estate.pages[0].id
                : undefined;

        this.state = {
            activePageId,
            imageToCropAspectRatio: 1,
            templateSelectorOpen: false,
            imageSelectorOpen: false,
            imageSelectorInitialOpen: this.intialOpenImageSelector(),
            imageCropperOpen: false,
            overlayOpen: false,
            activeSlot: undefined,
            imageToCrop: undefined,
            iconPlacer: undefined,
        };
    }

    private intialOpenImageSelector() {
        return (
            this.props.estate.images.length === 0 &&
            this.props.estate.pages.length === 1
        );
    }

    public render() {
        const imageSelectorTitle = this.state.imageSelectorInitialOpen
            ? 'Lisää kuvia kohteelle'
            : 'Valitse kuva tai lisää uusia';
        return (
            <div className="estate-page">
                {this.pageCountIndicator()}
                <div className="estate-container">
                    <div className="estate-sidebar-container">
                        <div className="estate-sidebar-edit-name-container">
                            <div className="estate-sidebar-edit-name">
                                <Description
                                    description={this.props.estate.description}
                                    onChanged={this.onDescriptionChanged}
                                />
                            </div>
                        </div>
                        <div className="estate-sidebar-toolbar">
                            <ButtonGroup
                                className="estate-sidebar-buttons"
                                minimal={true}
                            >
                                <Popover
                                    popoverClassName="estate-sidebar-download-popover"
                                    position={PopoverPosition.RIGHT_TOP}
                                    content={confirmDownload(
                                        this.props.estate,
                                        () => {
                                            const url = this.props.estate.getPdfUrl();
                                            Api.lastAction().then(() =>
                                                window.open(url),
                                            );
                                        },
                                    )}
                                    modifiers={{
                                        arrow: { enabled: true },
                                        flip: { enabled: true },
                                    }}
                                >
                                    <Button
                                        disabled={
                                            this.props.estate.pages.length === 0
                                        }
                                        icon="download"
                                        text="Lataa"
                                        intent={Intent.PRIMARY}
                                    />
                                </Popover>
                            </ButtonGroup>
                            <Divider />
                        </div>
                        <div className="estate-sidebar-thumbnails">
                            <SideBar
                                pages={this.props.estate.pages}
                                onMoveDown={(page) =>
                                    this.reorder(page, 'down')
                                }
                                onMoveUp={(page) => this.reorder(page, 'up')}
                                onDeletePage={this.deletePage}
                                onSelectPage={this.activatePage}
                                onAddNewPage={this.onTemplateSelectorOpen}
                            />
                        </div>
                    </div>
                    <div className="estate-page-editor-container">
                        <div className="estate-page-editor-inner-container">
                            {this.state.activePageId && (
                                <PageEdit
                                    // 'key' will cause text editor to update when
                                    // changing between different pages with same template
                                    key={this.state.activePageId}
                                    page={this.getActivePage(this.state)}
                                    slotActions={this.getSlotActions()}
                                />
                            )}
                        </div>
                    </div>
                </div>
                <ImageSelector
                    estateId={this.props.estate.id}
                    title={imageSelectorTitle}
                    isOpen={
                        this.state.imageSelectorOpen ||
                        this.state.imageSelectorInitialOpen
                    }
                    onClose={this.onImageSelectorClose}
                    images={this.props.estate.images}
                    imagesChanged={this.imagesChanged}
                />
                {this.state.activeSlot &&
                    this.state.imageToCrop &&
                    this.state.imageToCropAspectRatio && (
                        <ImageCropper
                            image={this.state.imageToCrop}
                            aspectRatio={this.state.imageToCropAspectRatio}
                            isOpen={this.state.imageCropperOpen}
                            onClose={this.onImageCropperClose}
                        />
                    )}
                <TemplateSelector
                    isOpen={this.state.templateSelectorOpen}
                    isDisabled={this.state.overlayOpen}
                    onClose={this.onTemplateSelectorClosed}
                />
                <LoadingOverlay isOpen={this.state.overlayOpen} />
                {this.state.iconPlacer && (
                    <IconPlacer
                        isOpen={this.state.iconPlacer.isOpen}
                        image={this.state.iconPlacer.image}
                        onClose={this.onIconPlacerClose}
                        slotSize={this.state.iconPlacer.slotSize}
                        icons={this.getActiveSlotIcons(this.state)}
                    />
                )}
            </div>
        );
    }

    private pageCountIndicator() {
        if (!this.state.activePageId) {
            return null;
        }
        const page = this.getActivePage(this.state);
        const { pageNumberText, totalPageCount } = getPageCountInfo(
            this.props.estate.pages,
            page,
        );

        return (
            <div className="estate-page-number">
                <span>
                    Sivu {pageNumberText}/{totalPageCount}
                </span>
            </div>
        );
    }

    private getActivePage(state: Readonly<IEstateState>): IPage {
        if (!state.activePageId) {
            throw new Error(
                `Cannot get active page. State ${JSON.stringify(this.state)}`,
            );
        }
        return this.props.estate.pages.filter(
            (p) => p.id === state.activePageId,
        )[0];
    }

    private getActiveSlotIcons(
        state: Readonly<IEstateState>,
    ): ReadonlyArray<ITemplateIcon> {
        return this.getActivePage(state).parameters.find(
            (p) => p.slotName === state.activeSlot,
        )!.templateIcons;
    }

    private getSlotActions() {
        return {
            imageSlotActions: {
                changeImage: (slotName: string, aspectRatio: number) =>
                    this.onImageSelectorOpen(slotName, aspectRatio),
                cropImage: (slotName: string, aspectRatio: number) =>
                    this.onImageCropperOpen(slotName, aspectRatio),
                setMarker: (slotName: string, slotSize: IDimensions) => {
                    this.onIconPlacerOpen(slotName, slotSize);
                },
            },
            textSlotActions: {
                setText: (slotName: string, text: string) =>
                    this.onSlotTextChanged(slotName, text),
                setOpacity: (slotName: string, opacity: number) =>
                    this.onSlotOpacityChanged(slotName, opacity),
                toggleInvertColors: (slotName: string) =>
                    this.onToggleInvertColors(slotName),
            },
        };
    }

    private imagesChanged = () => {
        this.props.imagesChanged();
    };

    private onDescriptionChanged = (description: string) => {
        this.props.changeDescription(description);
    };

    private onTemplateSelectorOpen = () => {
        this.setTemplateSelectorState('open');
    };

    private onTemplateSelectorClosed = async (
        selected?: TemplateName,
        file?: File,
    ) => {
        if (selected) {
            this.setOverlayState('open');
            const addedPageId = await this.props.addNewPage(selected, file);

            if (addedPageId) {
                this.setState((prevState) => {
                    return {
                        ...prevState,
                        activePageId: addedPageId,
                    };
                });
            }
            this.setOverlayState('close');
        }
        this.setTemplateSelectorState('close');
    };

    private setTemplateSelectorState(act: Act) {
        this.setState((prevState) => {
            return {
                ...prevState,
                templateSelectorOpen: act === 'open',
            };
        });
    }

    private setOverlayState(act: Act) {
        this.setState((prevState) => {
            return {
                ...prevState,
                overlayOpen: act === 'open',
            };
        });
    }

    private onImageSelectorOpen = (slotName: string, aspectRatio: number) => {
        this.setImageSelectorState('open', slotName, aspectRatio);
    };

    private onImageSelectorClose = async (selected?: IImage) => {
        if (!this.state.activeSlot || !selected) {
            this.setImageSelectorState('close');
        } else {
            const templateImage = {
                x: 0,
                y: 0,
                width: 1,
                height: 1,
                image: selected,
            };
            const prev = this.setImageSelectorState('close');
            this.onImageCropperOpenWithImage(
                templateImage,
                prev.prevSlotName,
                prev.prevAspectRatio,
            );
        }
    };

    private setImageSelectorState(
        act: Act,
        slotName?: string,
        aspectRatio?: number,
    ) {
        const prevSlotName = this.state.activeSlot!;
        const prevAspectRatio = this.state.imageToCropAspectRatio!;
        this.setState((prevState) => {
            return {
                ...prevState,
                activeSlot: slotName,
                imageToCropAspectRatio: aspectRatio,
                imageSelectorOpen: act === 'open',
                imageSelectorInitialOpen: false,
            };
        });
        return { prevSlotName, prevAspectRatio };
    }

    private onImageCropperOpenWithImage = (
        image: ITemplateImage,
        slotName: string,
        aspectRatio: number,
    ) => {
        this.setState((prevState) => {
            return {
                ...prevState,
                activeSlot: slotName,
                imageToCrop: image,
                imageToCropAspectRatio: aspectRatio,
                imageCropperOpen: true,
            };
        });
    };

    private onImageCropperOpen = (slotName: string, aspectRatio: number) => {
        const img = this.getImageOnSlot(slotName);
        if (!img) {
            return;
        }
        this.setImageCropperState('open', slotName, aspectRatio, img);
    };

    private onImageCropperClose = (croppedImage?: ITemplateImage) => {
        if (croppedImage) {
            this.props.imageCropped(
                croppedImage,
                this.state.activePageId!,
                this.state.activeSlot!,
            );
        }
        this.setImageCropperState('close');
    };

    private setImageCropperState(
        act: Act,
        slotName?: string,
        aspectRatio?: number,
        image?: ITemplateImage,
    ) {
        this.setState((prevState) => {
            return {
                ...prevState,
                activeSlot: slotName,
                imageToCrop: image,
                imageToCropAspectRatio: aspectRatio,
                imageCropperOpen: act === 'open',
            };
        });
    }

    private onIconPlacerOpen = (slotName: string, slotSize: IDimensions) => {
        const img = this.getImageOnSlot(slotName);
        if (!img) {
            return;
        }
        this.openIconPlacer(img, slotName, slotSize);
    };

    private onIconPlacerClose = (icons?: ITemplateIcon[]) => {
        if (icons) {
            this.props.iconsChanged(
                icons,
                this.state.activePageId!,
                this.state.activeSlot!,
            );
        }
        this.closeIconPlacer();
    };

    private openIconPlacer(
        img: ITemplateImage,
        slotName: string,
        slotSize: IDimensions,
    ) {
        this.setState(
            (prevState): IEstateState => {
                return {
                    ...prevState,
                    activeSlot: slotName,
                    iconPlacer: {
                        isOpen: true,
                        image: img,
                        slotSize,
                    },
                };
            },
        );
    }

    private closeIconPlacer() {
        this.setState(
            (prevState): IEstateState => {
                return {
                    ...prevState,
                    activeSlot: undefined,
                    iconPlacer: undefined,
                };
            },
        );
    }

    private getImageOnSlot(slotName: string) {
        return this.props.estate.pages
            .find((p) => p.id === this.state.activePageId)!
            .parameters.find((param) => param.slotName === slotName)!
            .templateImage;
    }

    private onSlotTextChanged = (slotName: string, text: string) => {
        this.props.changeSlotText(text, this.state.activePageId!, slotName);
    };

    private onSlotOpacityChanged = (slotName: string, opacity: number) => {
        this.props.changeSlotOpacity(
            opacity,
            this.state.activePageId!,
            slotName,
        );
    };

    private onToggleInvertColors(slotName: string) {
        this.props.toggleSlotInvertColors(this.state.activePageId!, slotName);
    }

    private activatePage = (page: IPage) => {
        this.setState((prev) => {
            return { ...prev, activePageId: page.id };
        });
    };

    private deletePage = (page: IPage) => {
        const resolveActivePage = (
            allPages: ReadonlyArray<IPage>,
            removedId?: number,
            activeId?: number,
        ) => {
            if (removedId === activeId) {
                if (allPages.length <= 1) {
                    return undefined;
                } else {
                    return allPages.filter((p) => p.id !== removedId)[0].id;
                }
            }
            return activeId;
        };

        this.setState(
            (prevState) => {
                return {
                    ...prevState,
                    activePageId: resolveActivePage(
                        this.props.estate.pages,
                        page.id,
                        prevState.activePageId,
                    ),
                };
            },
            () => this.props.deletePage(page),
        );
    };

    private reorder(page: IPage, direction: 'up' | 'down') {
        this.props.reorder(page, direction);
    }
}

function confirmDownload(
    estate: IEstate,
    onClick: (event: React.MouseEvent<HTMLElement>) => void,
) {
    const pageCount = getTotalPageCount(estate.pages);
    const buttonDisabled = pageCount === 0;
    const suspiciosPageCount =
        pageCount !== 1 && pageCount !== 2 && pageCount % 4 !== 0;

    return (
        <div style={{ padding: 10 }}>
            <H5>Lataa esite</H5>
            <p />
            {suspiciosPageCount ? (
                <div style={{ marginBottom: 10 }}>
                    <p>
                        HUOM! Esitteen sivumäärä {pageCount} ei ole jaollinen
                        neljällä. <br />
                        Taitto ei ole täydellinen.
                    </p>
                    <p>Oletko varma, että haluat ladata esitteen?</p>
                </div>
            ) : (
                <p style={{ marginBottom: 10 }}>
                    Esitteessä on {pageCount} sivua. Haluatko ladata esitteen?
                </p>
            )}
            <Button
                className={Classes.POPOVER_DISMISS}
                style={{ marginRight: 10 }}
            >
                Peruuta
            </Button>
            <Button
                intent={Intent.PRIMARY}
                disabled={buttonDisabled}
                className={Classes.POPOVER_DISMISS}
                onClick={onClick}
            >
                Lataa
            </Button>
        </div>
    );
}

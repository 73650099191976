import { Card, Elevation } from '@blueprintjs/core';
import React from 'react';
import { IPage } from '../../models/Page';
import { ISlotActions } from './ISlotActions';
import './PageEdit.scss';
import { createTemplatePageElement } from '../../templates/TemplateMapper';

export interface IPageEditProps {
    page: IPage;
    slotActions: ISlotActions;
}

export class PageEdit extends React.Component<IPageEditProps> {
    public render() {
        return (
            <div className="page-edit-container">
                <Card
                    elevation={Elevation.ZERO}
                    interactive={true}
                    className="page-edit-card"
                >
                    {createTemplatePageElement(
                        this.props.page,
                        this.props.slotActions,
                    )}
                </Card>
            </div>
        );
    }
}

import { CSSProperties } from 'react';

export const sheet: CSSProperties = {
    /* A4 */
    width: '210mm',
    height: '296mm',
    margin: '0',
    overflow: 'hidden',
    position: 'relative',
    boxSizing: 'border-box',
    pageBreakAfter: 'always',
    cursor: 'default',
};

export const container: CSSProperties = {
    margin: '0',
    overflow: 'hidden',
    position: 'absolute',

    /* page margins */
    top: '0.5cm',
    left: '0.5cm',
    bottom: '0.5cm',
    right: '0.5cm',
};

import React, { CSSProperties } from 'react';
import { IconNames, IconName } from '@blueprintjs/icons';
import { Icon } from '../components/common/Icons';
import './EditIcons.scss';

type OnClick = () => void;

export class EditIcons extends React.Component<
    {
        onChangeImage: OnClick;
        onCropImage: OnClick;
        onSetMarker?: OnClick;
        imageSet: boolean;
    },
    { hovered: boolean }
> {
    public state = { hovered: false };

    public render() {
        return (
            <div
                className="edit-icons-container"
                onMouseOver={() => this.setState({ hovered: true })}
                onMouseOut={() => this.setState({ hovered: false })}
            >
                <div className="edit-icons">
                    {this.getIcon(
                        IconNames.MEDIA,
                        'Valitse kuva',
                        this.props.onChangeImage,
                        true,
                    )}
                    {this.getIcon(
                        IconNames.SELECT,
                        'Rajaa kuvaa',
                        this.props.onCropImage,
                        this.props.imageSet,
                    )}
                    {this.props.onSetMarker &&
                        this.getIcon(
                            IconNames.MAP_MARKER,
                            'Aseta ikoni',
                            this.props.onSetMarker,
                            this.props.imageSet,
                        )}
                </div>

                <div
                    style={{
                        width: '100%',
                        height: '100%',
                        overflow: 'hidden',
                    }}
                >
                    {this.props.children}
                </div>
            </div>
        );
    }

    private getIcon(
        icon: IconName,
        tooltip: string,
        onClick: OnClick,
        showIcon: boolean,
    ) {
        const style: CSSProperties = showIcon
            ? { visibility: 'visible', padding: '10px' }
            : { visibility: 'hidden', padding: '10px' };

        return (
            <div style={style}>
                <Icon
                    icon={icon}
                    tooltip={tooltip}
                    hovered={this.state.hovered}
                    onClick={onClick}
                />
            </div>
        );
    }
}

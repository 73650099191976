import React from 'react';
import { container, sheet } from './styling';
import { TemplateImage } from './TemplateImage';
import { IPageWithOneImageAndOneTextProps } from './TemplateProps';
import { TemplateTextEditor } from './TemplateTextEditor';

export const Template2 = (props: IPageWithOneImageAndOneTextProps) => {
    return (
        <div style={sheet}>
            <div style={container}>
                <TemplateImage img={props.img} style={imageSection} />
                <TemplateTextEditor
                    minHeight={150}
                    text={props.text}
                    style={textSection}
                />
            </div>
        </div>
    );
};

const imageSection = {
    display: 'flex',
    overflow: 'hidden',
    width: '100%',
    height: '100%',
};

const textSection = {
    position: 'absolute' as const,
    bottom: 0,
    left: 0,
    right: 0,
    padding: '0.5cm',
};

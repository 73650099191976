import { EditableText, Classes } from '@blueprintjs/core';
import React from 'react';

interface IDescriptionProps {
    description?: string;
    onChanged: (description: string) => void;
}
interface IDescriptionState {
    editedDescription: string;
    isInitialized: boolean;
}
export class Description extends React.Component<
    IDescriptionProps,
    IDescriptionState
> {
    public state = { editedDescription: '', isInitialized: false };

    public render() {
        return (
            <EditableText
                className={!this.state.isInitialized ? Classes.SKELETON : ''}
                placeholder="Kohteen nimi..."
                selectAllOnFocus
                value={this.state.editedDescription}
                onConfirm={this.onConfirm}
                onCancel={this.onCancel}
                onChange={this.onChange}
                multiline={true}
                minLines={3}
            />
        );
    }

    public static getDerivedStateFromProps(
        props: IDescriptionProps,
        state: IDescriptionState,
    ) {
        // This makes the component behave as expected. Once it's mounted one
        // cannot change the value just by changing the props from outside
        // because the component also has some internal state.
        if (!state.isInitialized && props.description) {
            return {
                editedDescription: props.description,
                isInitialized: true,
            };
        }
        return null;
    }

    private onConfirm = (description: string) => {
        this.setStateWhenInitialized(description);
        this.props.onChanged(description);
    };

    private onChange = (description: string) => {
        this.setStateWhenInitialized(description);
    };

    private onCancel = (lastConfirmedValue: string) => {
        this.setStateWhenInitialized(lastConfirmedValue);
    };

    private setStateWhenInitialized(description: string) {
        this.setState((prevState) => {
            if (!prevState.isInitialized) {
                throw new Error('Should be initialized');
            }
            return {
                isInitialized: true,
                editedDescription: description,
            };
        });
    }
}

/* eslint-disable @typescript-eslint/no-var-requires */
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import 'normalize.css/normalize.css';
import './index.scss';

const render = (Component: any) => {
    // eslint-disable-next-line
    return ReactDOM.render(<Component />, document.getElementById('root'));
};

declare const module: any;

if (module.hot) {
    module.hot.accept('./App', () => {
        const NextApp = require('./App').default;
        render(NextApp);
    });
}

render(App);

import React, { CSSProperties } from 'react';
import { ITemplateTextProps } from './TemplateProps';
import {
    Editor,
    EditorState,
    DraftBlockType,
    DraftHandleValue,
    DraftEditorCommand,
    RichUtils,
} from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import { stateFromHTML } from 'draft-js-import-html';
import { Alignment, TextEditorControls } from './TextEditorControls';

import './TemplateTextEditor.scss';
import 'draft-js/dist/Draft.css';

interface ITemplateTextEditor {
    text: ITemplateTextProps;
    style: CSSProperties;
    minHeight: number;
    maxHeight?: number;
    maxWidth?: number;
    largeFontSize?: boolean;
    hideOpacityControls?: boolean;
}

export class TemplateTextEditor extends React.Component<
    ITemplateTextEditor,
    {
        areTextEditorControlsOpen: boolean;
        editorState: EditorState;
        alignment: Alignment;
    }
> {
    private latest: { editorState: EditorState; alignment: Alignment };
    private textEditorRef = React.createRef<TextEditor>();

    constructor(props: ITemplateTextEditor) {
        super(props);

        const text = props.text.text;
        const editorState = text
            ? editorStateFromHtml(text)
            : EditorState.createEmpty();
        const alignment = extractCustomAlign(text);

        this.state = {
            editorState,
            areTextEditorControlsOpen: false,
            alignment,
        };
        this.latest = {
            editorState,
            alignment,
        };
    }

    public componentDidMount() {
        const editorContent = document.querySelector(
            '.public-DraftEditor-content',
        );
        if (editorContent) {
            if ((editorContent as any).style) {
                const style = (editorContent as any).style;
                style.setProperty('min-height', this.props.minHeight + 'px');
                style.setProperty('max-height', this.props.maxHeight + 'px');
                style.setProperty('max-width', this.props.maxWidth + 'px');
            }
        }
    }

    public render() {
        const text = this.props.text;
        const editorState = this.state.editorState;
        const currentStyle = editorState.getCurrentInlineStyle();
        const selection = editorState.getSelection();
        const currentBlock = editorState
            .getCurrentContent()
            .getBlockForKey(selection.getStartKey())
            .getType();

        const opacity = this.props.hideOpacityControls ? 1 : text.opacity;
        const isInvertColors = this.props.hideOpacityControls
            ? false
            : text.invertColors;

        const changeOpacity = this.props.hideOpacityControls
            ? undefined
            : text.onOpacityChanged;

        const changeInvertColors = this.props.hideOpacityControls
            ? undefined
            : text.onInvertColorsChanged;

        return (
            <div
                className="template-text-editor"
                style={{
                    ...this.props.style,
                    ...getTextEditorColors(isInvertColors, opacity),
                }}
                onClick={this.focus}
            >
                {this.state.areTextEditorControlsOpen && (
                    <TextEditorControls
                        currentBlock={currentBlock}
                        currentAlignment={this.state.alignment}
                        currentStyle={currentStyle}
                        currentOpacity={opacity}
                        currentInvertColors={isInvertColors}
                        changeBlockType={this.onChangeBlockType}
                        changeAlignment={this.onChangeAlignment}
                        changeStyle={this.onChangeStyle}
                        changeBackgroundOpacity={changeOpacity}
                        changeInvertColors={changeInvertColors}
                    />
                )}

                <TextEditor
                    editorState={this.state.editorState}
                    largeFontSize={this.props.largeFontSize}
                    textAlignment={this.state.alignment}
                    handleKeyCommand={this.onHandleKeyCommand}
                    onChange={this.onChange}
                    onFocus={() => {
                        this.setState({ areTextEditorControlsOpen: true });
                    }}
                    onBlur={() => {
                        this.setState({ areTextEditorControlsOpen: false });
                        this.triggerTextChanged();
                    }}
                    ref={this.textEditorRef}
                />
            </div>
        );
    }

    private focus = () => {
        if (this.textEditorRef.current) {
            this.textEditorRef.current.focus();
        }
    };

    private onChangeBlockType = (type: DraftBlockType) => {
        this.onChange(RichUtils.toggleBlockType(this.state.editorState, type));
    };

    private onChangeAlignment = (alignment: Alignment) => {
        this.latest.alignment = alignment;
        this.setState({ alignment });
    };

    private onChangeStyle = (style: string) => {
        this.onChange(
            RichUtils.toggleInlineStyle(this.state.editorState, style),
        );
    };

    private onChange = (newState: EditorState) => {
        this.latest.editorState = newState;
        this.setState({ editorState: newState });
    };

    private triggerTextChanged = () => {
        const html = htmlFromEditorState(this.latest.editorState);
        const alignedHtml = addCustomAlignDiv(html, this.latest.alignment);
        this.props.text.onTextChanged(alignedHtml);
    };

    private onHandleKeyCommand = (command: DraftEditorCommand) => {
        if (command === 'bold') {
            this.onChangeStyle('BOLD');
            return 'handled';
        }
        return 'not-handled';
    };
}

class TextEditor extends React.Component<
    {
        editorState: EditorState;
        largeFontSize?: boolean;
        textAlignment: Alignment;
        handleKeyCommand: (command: DraftEditorCommand) => DraftHandleValue;
        onChange: (editorState: EditorState) => void;
        onFocus: () => void;
        onBlur: () => void;
    },
    unknown
> {
    private editorRef = React.createRef<Editor>();

    public render() {
        const props = this.props;
        const fontSizeClassName = props.largeFontSize
            ? 'template-text-large-font-size'
            : 'template-text';
        return (
            <div className={`text-editor-container ${fontSizeClassName}`}>
                <Editor
                    editorState={props.editorState}
                    onChange={props.onChange}
                    placeholder="Lisää teksti..."
                    tabIndex={0}
                    onFocus={props.onFocus}
                    onBlur={props.onBlur}
                    textAlignment={props.textAlignment}
                    handleKeyCommand={props.handleKeyCommand}
                    ref={this.editorRef}
                />
            </div>
        );
    }

    public focus() {
        if (this.editorRef.current) {
            this.editorRef.current.focus();
        }
    }
}
function editorStateFromHtml(text: string) {
    const state = stateFromHTML(text);
    return EditorState.createWithContent(state);
}

function htmlFromEditorState(state: EditorState) {
    return stateToHTML(state.getCurrentContent());
}

const CUSTOM_ALIGN_CLASS_NAME = 'custom-div-align';

function addCustomAlignDiv(html: string, alignment?: Alignment) {
    return `<div class="${CUSTOM_ALIGN_CLASS_NAME}" style="text-align:${alignment}">${html}</div>`;
}

function extractCustomAlign(html: string) {
    const doc = document.createElement('div');
    doc.innerHTML = html;
    const elem = doc.firstChild as HTMLElement | null;
    if (elem && elem.className === CUSTOM_ALIGN_CLASS_NAME) {
        return elem.style.textAlign as Alignment;
    } else {
        return 'left';
    }
}

function getTextEditorColors(
    isInvertColors: boolean,
    opacity: number,
): Partial<CSSProperties> {
    if (isInvertColors) {
        return {
            backgroundColor: `rgba(0, 0, 0, ${opacity})`,
            color: `rgb(255,255,255)`,
        };
    }
    return {
        backgroundColor: `rgba(255, 255, 255, ${opacity})`,
        color: `rgb(0,0,0)`,
    };
}

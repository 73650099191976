import React from 'react';
import { container, sheet } from './styling';
import { IPageWithThreeImages } from './TemplateProps';
import { TemplateImage } from './TemplateImage';

export const Template3 = (props: IPageWithThreeImages) => {
    return (
        <div style={sheet}>
            <div style={container}>
                <TemplateImage img={props.img1} style={topSection} />
                <div style={bottomSection}>
                    <TemplateImage img={props.img2} style={bottomLeft} />
                    <TemplateImage img={props.img3} style={bottomRight} />
                </div>
            </div>
        </div>
    );
};

const topSection = {
    display: 'flex',
    overflow: 'hidden',
    width: '100%',
    height: '50%',
    marginBottom: '0.5cm',
    position: 'relative' as const,
};

const bottomSection = {
    display: 'flex',
    height: '50%',
};

const bottomLeft = {
    overflow: 'hidden',
    display: 'inlineBlock',
    marginRight: '0.25cm',
    position: 'relative' as const,
    width: '50%',
};

const bottomRight = {
    overflow: 'hidden',
    display: 'inlineBlock',
    marginLeft: '0.25cm',
    position: 'relative' as const,
    width: '50%',
};

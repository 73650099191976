import React from 'react';
import { IPage, TemplateName } from '../models/Page';
import {
    IPdfProps,
    IPageWithOneImageAndOneTextProps,
    IPageWithThreeImages,
    ITemplateImageProps,
    ITemplateTextProps,
    IPageWithTwoImagesAndOneText,
    IPageWithTwoImages,
    IPageWithThreeImagesAndOneText,
    IPageWithFourImagesAndOneText,
    IPageWithFiveImages,
} from './TemplateProps';
import {
    ITemplateParameter,
    ITemplateImage,
    ITemplateIcon,
} from '../models/TemplateParameter';
import { neverGetsHere } from '../utils/unreachable';
import {
    ISlotActions,
    IImageSlotActions,
    ITextSlotActions,
} from '../components/EstateEditor/ISlotActions';
import { IDimensions } from '../models/Dimensions';
import { Pdf } from './Pdf';
import { Template1 } from './Template1';
import { Template2 } from './Template2';
import { Template3 } from './Template3';
import { Template4 } from './Template4';
import { Template5 } from './Template5';
import { Template6 } from './Template6';
import { Template7 } from './Template7';
import { Template8 } from './Template8';
import { Template9 } from './Template9';
import { Template10 } from './Template10';
import { Static1 } from './Static1';

// TODO: For safety, map the names with the ones used below in mapping.
export function getTemplateParameterSlotNames(templateName: TemplateName) {
    switch (templateName) {
        case 'pdf':
        case 'static1':
            return [];
        case 'template1':
        case 'template2':
        case 'template6':
            return ['img', 'text'];
        case 'template3':
        case 'template10':
            return ['img1', 'img2', 'img3'];
        case 'template4':
            return ['img1', 'img2', 'text'];
        case 'template5':
            return ['img1', 'img2'];
        case 'template7':
            return ['img1', 'img2', 'img3', 'text'];
        case 'template8':
            return ['img1', 'img2', 'img3', 'img4', 'text'];
        case 'template9':
            return ['img1', 'img2', 'img3', 'img4', 'img5'];
        default:
            neverGetsHere(templateName);
            throw new Error(
                `No parameters slot names found for template ${templateName}`,
            );
    }
}

export function createTemplatePageElement(
    page: IPage,
    slotActions: ISlotActions,
): React.FunctionComponentElement<any> {
    switch (page.templateName) {
        case 'pdf':
            return React.createElement(Pdf, mapPdfProps(page));

        case 'static1':
            return React.createElement(Static1);

        case 'template1':
            return React.createElement(
                Template1,
                mapTemplateWithOneImageAndOneTextProps(page, slotActions),
            );

        case 'template2':
            return React.createElement(
                Template2,
                mapTemplateWithOneImageAndOneTextProps(page, slotActions),
            );

        case 'template3':
            return React.createElement(
                Template3,
                mapTemplateWithThreeImages(page, slotActions.imageSlotActions),
            );

        case 'template4':
            return React.createElement(
                Template4,
                mapTemplateWithTwoImagesAndOneText(page, slotActions),
            );

        case 'template5':
            return React.createElement(
                Template5,
                mapTemplateWithTwoImages(page, slotActions.imageSlotActions),
            );

        case 'template6':
            return React.createElement(
                Template6,
                mapTemplateWithOneImageAndOneTextProps(page, slotActions),
            );

        case 'template7':
            return React.createElement(
                Template7,
                mapTemplateWithThreeImagesAndOneTextProps(page, slotActions),
            );

        case 'template8':
            return React.createElement(
                Template8,
                mapTemplateWithFourImagesAndOneTextProps(page, slotActions),
            );

        case 'template9':
            return React.createElement(
                Template9,
                mapTemplateWithFiveImages(page, slotActions),
            );

        case 'template10':
            return React.createElement(
                Template10,
                mapTemplateWithThreeImages(page, slotActions.imageSlotActions),
            );

        default:
            neverGetsHere(page.templateName);
            throw new Error(
                `No template mapper found for template ${page.templateName}`,
            );
    }
}

function mapPdfProps(page: IPage): IPdfProps {
    return {
        kind: 'pdf',
        file: page.file!,
    };
}

function mapTemplateWithOneImageAndOneTextProps(
    page: IPage,
    slotActions: ISlotActions,
): IPageWithOneImageAndOneTextProps {
    const params = page.parameters;
    return {
        kind: page.templateName,
        img: getImageSlotProps('img', params, slotActions.imageSlotActions),
        text: getTextSlotProps('text', params, slotActions.textSlotActions),
    };
}

function mapTemplateWithThreeImages(
    page: IPage,
    imageSlotActions: IImageSlotActions,
): IPageWithThreeImages {
    const params = page.parameters;
    return {
        kind: page.templateName,
        img1: getImageSlotProps('img1', params, imageSlotActions),
        img2: getImageSlotProps('img2', params, imageSlotActions),
        img3: getImageSlotProps('img3', params, imageSlotActions),
    };
}

function mapTemplateWithTwoImagesAndOneText(
    page: IPage,
    slotActions: ISlotActions,
): IPageWithTwoImagesAndOneText {
    const params = page.parameters;
    return {
        kind: page.templateName,
        img1: getImageSlotProps('img1', params, slotActions.imageSlotActions),
        img2: getImageSlotProps('img2', params, slotActions.imageSlotActions),
        text: getTextSlotProps('text', params, slotActions.textSlotActions),
    };
}

function mapTemplateWithThreeImagesAndOneTextProps(
    page: IPage,
    slotActions: ISlotActions,
): IPageWithThreeImagesAndOneText {
    const params = page.parameters;
    return {
        kind: page.templateName,
        img1: getImageSlotProps('img1', params, slotActions.imageSlotActions),
        img2: getImageSlotProps('img2', params, slotActions.imageSlotActions),
        img3: getImageSlotProps('img3', params, slotActions.imageSlotActions),
        text: getTextSlotProps('text', params, slotActions.textSlotActions),
    };
}

function mapTemplateWithFourImagesAndOneTextProps(
    page: IPage,
    slotActions: ISlotActions,
): IPageWithFourImagesAndOneText {
    const params = page.parameters;
    return {
        kind: page.templateName,
        img1: getImageSlotProps('img1', params, slotActions.imageSlotActions),
        img2: getImageSlotProps('img2', params, slotActions.imageSlotActions),
        img3: getImageSlotProps('img3', params, slotActions.imageSlotActions),
        img4: getImageSlotProps('img4', params, slotActions.imageSlotActions),
        text: getTextSlotProps('text', params, slotActions.textSlotActions),
    };
}
function mapTemplateWithFiveImages(
    page: IPage,
    slotActions: ISlotActions,
): IPageWithFiveImages {
    const params = page.parameters;
    return {
        kind: page.templateName,
        img1: getImageSlotProps('img1', params, slotActions.imageSlotActions),
        img2: getImageSlotProps('img2', params, slotActions.imageSlotActions),
        img3: getImageSlotProps('img3', params, slotActions.imageSlotActions),
        img4: getImageSlotProps('img4', params, slotActions.imageSlotActions),
        img5: getImageSlotProps('img5', params, slotActions.imageSlotActions),
    };
}

function mapTemplateWithTwoImages(
    page: IPage,
    imageSlotActions: IImageSlotActions,
): IPageWithTwoImages {
    const params = page.parameters;
    return {
        kind: page.templateName,
        img1: getImageSlotProps('img1', params, imageSlotActions),
        img2: getImageSlotProps('img2', params, imageSlotActions),
    };
}

function getImageSlotProps(
    slotName: string,
    parameters: ReadonlyArray<ITemplateParameter>,
    imageSlotActions: IImageSlotActions,
): ITemplateImageProps {
    const img = getSlotNameImage(slotName, parameters);
    const icons = getSlotNameIcons(slotName, parameters);
    const onChangeImage = (aspectRatio: number) =>
        imageSlotActions.changeImage(slotName, aspectRatio);
    const onCropImage = (aspectRatio: number) =>
        imageSlotActions.cropImage(slotName, aspectRatio);
    const onSetMarker = (slotSize: IDimensions) =>
        imageSlotActions.setMarker(slotName, slotSize);
    return { img, icons, onChangeImage, onCropImage, onSetMarker };
}

function getSlotNameImage(
    slotName: string,
    parameters: ReadonlyArray<ITemplateParameter>,
): ITemplateImage | undefined {
    return parameters.filter((p) => p.slotName === slotName)[0].templateImage;
}

function getSlotNameIcons(
    slotName: string,
    parameters: ReadonlyArray<ITemplateParameter>,
): ReadonlyArray<ITemplateIcon> {
    return parameters.filter((p) => p.slotName === slotName)[0].templateIcons;
}

function getTextSlotProps(
    slotName: string,
    parameters: ReadonlyArray<ITemplateParameter>,
    textSlotActions: ITextSlotActions,
): ITemplateTextProps {
    const parameter = parameters.find((p) => p.slotName === slotName)!;
    const result: ITemplateTextProps = {
        text: parameter.templateText ? parameter.templateText.text : '',
        opacity: parameter.templateText ? parameter.templateText.opacity : 0,
        invertColors: !!(
            parameter.templateText && parameter.templateText.invertColors
        ),
        onTextChanged: (text) => textSlotActions.setText(slotName, text),
        onOpacityChanged: (opacity) =>
            textSlotActions.setOpacity(slotName, opacity),
        onInvertColorsChanged: () =>
            textSlotActions.toggleInvertColors(slotName),
    };
    return result;
}

import { Dialog, Classes, Button } from '@blueprintjs/core';
import React from 'react';
import ReactCrop, { Crop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import './ImageCropper.scss';
import { ITemplateImage } from '../../models/TemplateParameter';

export interface IImageCropperProps {
    image: ITemplateImage;
    aspectRatio: number;
    isOpen: boolean;
    onClose: (croppedImage?: ITemplateImage) => void;
}

interface IImageCropperState {
    crop: Crop;
}

export class ImageCropper extends React.Component<
    IImageCropperProps,
    IImageCropperState
> {
    public constructor(props: IImageCropperProps) {
        super(props);
        const img = props.image;
        this.state = {
            crop: {
                x: img.x * 100,
                y: img.y * 100,
                width: img.width * 100,
                aspect: props.aspectRatio,
            },
        };
    }

    public render() {
        return (
            <Dialog
                className={Classes.DIALOG + ' image-cropper-dialog'}
                canOutsideClickClose={false}
                isOpen={this.props.isOpen}
                onClose={this.onCancel}
                title="Rajaa kuva"
            >
                <div className={Classes.DIALOG_BODY}>
                    <ReactCrop
                        style={{
                            maxWidth: '96vw',
                            maxHeight: 'calc(100vh - 180px)',
                        }}
                        src={this.props.image.image.mediumUrl}
                        crop={this.state.crop}
                        onChange={this.onCropChange}
                        keepSelection={true}
                    />
                </div>
                <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Button onClick={this.onCancel}>Peruuta</Button>
                        <Button onClick={this.onSave}>Ok</Button>
                    </div>
                </div>
            </Dialog>
        );
    }

    private onCropChange = (crop: Crop) => {
        this.setState({ crop });
    };

    private onCancel = () => this.props.onClose();

    private onSave = () => {
        if (
            this.isZero(this.state.crop.width) ||
            this.isZero(this.state.crop.height)
        ) {
            // No area selected, do nothing.
            this.props.onClose();
        } else {
            const width =
                this.state.crop.width != null ? this.state.crop.width : 100;
            const height =
                this.state.crop.height != null ? this.state.crop.height : 100;

            this.props.onClose({
                ...this.props.image,
                x: this.state.crop.x / 100,
                y: this.state.crop.y / 100,
                width: width / 100,
                height: height / 100,
            });
        }
    };

    private isZero(val?: number): boolean {
        return !val || val === 0;
    }
}

import React from 'react';
import { Checkbox as BpCheckbox, Navbar } from '@blueprintjs/core';
import { IUser } from '../../Models';
import './FilterTopbar.scss';
import { UserSelect } from './UserSelect';

export interface IFilters {
    readonly user: IUser;
    readonly showRemoved: boolean;
    readonly showActive: boolean;
}

interface IFilterTopbarProps {
    readonly userGroups: ReadonlyArray<ReadonlyArray<IUser>>;
    readonly filters: IFilters;
    readonly filteringChanged: (filters: IFilters) => void;
}

export class FilterTopbar extends React.Component<IFilterTopbarProps> {
    public render() {
        return (
            <Navbar className="filter-topbar">
                <Navbar.Group>
                    <Navbar.Divider />
                    <div className="select-user-label">Käyttäjä</div>
                    <UserSelect
                        className="select-user"
                        selected={this.props.filters.user}
                        userGroups={this.props.userGroups}
                        onChange={this.filterByUser}
                    />
                    <CheckBox
                        className="show-active-checkbox"
                        label="Aktiiviset"
                        checked={this.props.filters.showActive}
                        onChange={this.filterByActive}
                    />
                    <CheckBox
                        className="show-deleted-checkbox"
                        label="Poistetut"
                        checked={this.props.filters.showRemoved}
                        onChange={this.filterByRemoved}
                    />
                </Navbar.Group>
            </Navbar>
        );
    }

    private filterByUser = (user: IUser) => {
        this.reportChange({ user });
    };

    private filterByRemoved = (showRemoved: boolean) => {
        this.reportChange({ showRemoved });
    };

    private filterByActive = (showActive: boolean) => {
        this.reportChange({ showActive });
    };

    private reportChange(change: Partial<IFilters>) {
        const newFilters = { ...this.props.filters, ...change };
        this.props.filteringChanged(newFilters);
    }
}

const CheckBox = (props: {
    label: string;
    className: string;
    checked: boolean;
    onChange: (value: boolean) => void;
}) => {
    return (
        <BpCheckbox
            className={props.className}
            checked={props.checked}
            label={props.label}
            onChange={(event: React.FormEvent<HTMLInputElement>) => {
                props.onChange(event.currentTarget.checked);
            }}
        />
    );
};

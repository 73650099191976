import { Dialog, Classes, Card, Elevation } from '@blueprintjs/core';
import { getProps as getFilePondProps } from '../../api/FilePondClient';
import { FilePond } from 'react-filepond';
import { FilePondFile } from 'filepond';
import React from 'react';
import { IImage } from '../../models/Image';
import { GridItem } from './GridItem';

export class ImageSelector extends React.Component<
    {
        estateId: number;
        title: string;
        isOpen: boolean;
        onClose: (selected?: IImage) => void;
        images: ReadonlyArray<IImage>;
        imagesChanged: () => void;
    },
    { images: ReadonlyArray<IImage> }
> {
    public render() {
        return (
            <Dialog
                style={{ minWidth: '90vw', minHeight: '80vh' }}
                canOutsideClickClose={false}
                isOpen={this.props.isOpen}
                onClose={() => this.onClose()}
                title={this.props.title}
            >
                <div className={Classes.DIALOG_BODY}>
                    <div
                        style={{
                            display: 'flex',
                            flexWrap: 'wrap',
                        }}
                    >
                        {this.props.images.length === 0 ? (
                            <div
                                style={{
                                    width: '100%',
                                    textAlign: 'center',
                                    padding: '40px 0px',
                                }}
                            >
                                <h3>
                                    Sinulla ei ole yhtään kuvaa ladattuna tähän
                                    esitteeseen. Lataa kuvia alla olevan ohjeen
                                    mukaan.
                                </h3>
                            </div>
                        ) : null}
                        {this.props.images.map((i) => (
                            <GridItem
                                onSelect={() => this.onClose(i)}
                                key={i.id}
                            >
                                <img src={i.thumbnailUrl} />
                            </GridItem>
                        ))}
                    </div>
                    <Card elevation={Elevation.ZERO} interactive={true}>
                        <FilePond
                            // This is a bit nasty that this mr. talks directly to server
                            {...getFilePondProps(this.props.estateId)}
                            onprocessfile={this.onprocessfile}
                            onremovefile={this.onremovefile}
                        />
                    </Card>
                </div>
            </Dialog>
        );
    }

    private onClose = (i?: IImage) => {
        this.props.onClose(i);
    };

    private onprocessfile = (err: any, file: FilePondFile) => {
        this.processError(file, err);
        this.props.imagesChanged();
    };

    private onremovefile = () => {
        this.props.imagesChanged();
    };

    private processError(file: FilePondFile, err?: any) {
        // FilePond does not export the err type :|
        // And it documents that error should not be present if all was good,
        // but does not seem to work like that, that's why err.main and err.sub,
        // err itself seems to be present.
        if (err && (err.main || err.sub)) {
            // TODO: do something clever
            throw new Error(`Failed to upload file ${file}: ${err}`);
        }
    }
}

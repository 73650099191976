import queryString from 'query-string';
import React from 'react';
import { RouteComponentProps } from 'react-router';
import { EstateClient } from '../../api/EstateClient';
import { IEstate } from '../../models/Estate';
import { Estate } from './Estate';
import { TemplateName, IPage } from '../../models/Page';
import { getTemplateParameterSlotNames } from '../../templates/TemplateMapper';
import * as PageClient from '../../api/PageClient';
import {
    ITemplateParameter,
    ITemplateImage,
    ITemplateText,
    ITemplateIcon,
} from '../../models/TemplateParameter';
import * as array from '../../utils/array';
import { ErrorOverlay } from '../common/ErrorOverlay';
import { LoadingOverlay } from '../common/LoadingOverlay';

export class EstateController extends React.Component<
    RouteComponentProps,
    { estate?: IEstate }
> {
    public constructor(props: RouteComponentProps) {
        super(props);
        this.state = { estate: undefined };
    }

    public async componentDidMount() {
        const id =
            queryString && queryString.parse(this.props.location.search).id;
        if (!!id && !Array.isArray(id)) {
            this.setState({
                estate: await EstateClient.getEstate(parseInt(id, 10)),
            });
        }
    }

    public render() {
        if (this.state.estate) {
            return <EstateStateControl estate={this.state.estate} />;
        }

        return <LoadingOverlay isOpen={true} />;
    }
}

interface IEstateStateControlState {
    estate: IEstate;
    error?: any;
}

class EstateStateControl extends React.Component<
    { estate: IEstate },
    IEstateStateControlState
> {
    public state = { estate: this.props.estate, error: undefined };

    public render() {
        return (
            <div>
                <Estate
                    estate={this.state.estate}
                    imagesChanged={this.onImagesChanged}
                    addNewPage={this.onAddNewPage}
                    changeDescription={this.onChangeDescription}
                    imageCropped={this.onImageCropped}
                    iconsChanged={this.onIconsChanged}
                    changeSlotText={this.onChangeSlotText}
                    changeSlotOpacity={this.onChangeSlotOpacity}
                    toggleSlotInvertColors={this.onToggleSlotInvertColors}
                    deletePage={this.onDeletePage}
                    reorder={this.onReorder}
                />
                <ErrorOverlay
                    isOpen={!!this.state.error}
                    error={this.state.error}
                    summary="Virhe palvelimen kanssa keskustelussa"
                    onClose={this.hideError}
                />
            </div>
        );
    }

    private showError = (error: any) => {
        this.setState((prevState) => {
            return {
                ...prevState,
                error,
            };
        });
    };

    private hideError = () => {
        this.setState((prevState) => {
            return { ...prevState, error: undefined };
        });
    };

    private onImagesChanged = () => {
        EstateClient.getEstate(this.state.estate.id)
            .then((e) => {
                this.setState((prevState) => {
                    return {
                        ...prevState,
                        estate: { ...prevState.estate, images: e.images },
                    };
                });
            })
            .catch(this.showError);
    };

    private onChangeDescription = (description: string) => {
        this.setState(
            (prevState) => {
                return {
                    ...prevState,
                    estate: {
                        ...prevState.estate,
                        description,
                    },
                };
            },
            () => {
                EstateClient.update(this.state.estate).catch((err) =>
                    this.showError(err),
                );
            },
        );
    };

    private onAddNewPage = async (selected: TemplateName, file?: File) => {
        const createPage =
            selected === 'pdf'
                ? () => PageClient.createPdfPage(this.state.estate!, file!)
                : () => {
                      const slotNames = getTemplateParameterSlotNames(selected);
                      return PageClient.createPage(
                          this.state.estate!,
                          selected,
                          slotNames,
                      );
                  };

        return createPage()
            .then((page) => {
                this.setState((prevState) => {
                    return {
                        ...prevState,
                        estate: {
                            ...prevState.estate,
                            pages: prevState.estate.pages.concat(page),
                        },
                    };
                });
                return page.id;
            })
            .catch((err) => {
                this.showError(err);
                return undefined;
            });
    };

    private onImageCropped = (
        templateImage: ITemplateImage,
        pageId: number,
        slotName: string,
    ) => {
        this.updateSlotParameter({ templateImage }, pageId, slotName);
    };

    private onIconsChanged = (
        icons: ITemplateIcon[],
        pageId: number,
        slotName: string,
    ) => {
        const targetParam = this.getSlotTemplateParameter(
            this.state,
            pageId,
            slotName,
        );
        this.updateSlotParameter(
            {
                ...targetParam,
                templateIcons: icons,
            },
            pageId,
            slotName,
        );
    };

    private onChangeSlotText = (
        text: string,
        pageId: number,
        slotName: string,
    ) => {
        const prevText = this.getSlotTextParameter(
            this.state,
            pageId,
            slotName,
        );
        this.updateSlotParameter(
            { templateText: { ...prevText, text } },
            pageId,
            slotName,
        );
    };

    private onChangeSlotOpacity = (
        opacity: number,
        pageId: number,
        slotName: string,
    ) => {
        const prevText = this.getSlotTextParameter(
            this.state,
            pageId,
            slotName,
        );
        this.updateSlotParameter(
            { templateText: { ...prevText, opacity } },
            pageId,
            slotName,
        ).then(); // No need to wait promise
    };
    private onToggleSlotInvertColors = (pageId: number, slotName: string) => {
        const prevText = this.getSlotTextParameter(
            this.state,
            pageId,
            slotName,
        );
        this.updateSlotParameter(
            {
                templateText: {
                    ...prevText,
                    invertColors: !prevText.invertColors,
                },
            },
            pageId,
            slotName,
        ).then(); // No need to wait promise
    };

    private getSlotTextParameter(
        state: IEstateStateControlState,
        pageId: number,
        slotName: string,
    ): ITemplateText {
        const targetParam = this.getSlotTemplateParameter(
            state,
            pageId,
            slotName,
        );
        return targetParam.templateText
            ? targetParam.templateText
            : { text: '', opacity: 0, invertColors: false };
    }

    private onDeletePage = async (page: IPage) => {
        const deletePage =
            page.templateName === 'pdf'
                ? () => PageClient.deletePdfPage(page)
                : () => PageClient.deletePage(page);

        deletePage()
            .then(() => {
                this.setState((prevState) => {
                    const newPages = array.removeItem(
                        prevState.estate.pages,
                        page,
                    );
                    return {
                        ...prevState,
                        estate: {
                            ...prevState.estate,
                            pages: newPages,
                        },
                    };
                });
            })
            .catch(this.showError);
    };

    private onReorder = (page: IPage, direction: 'up' | 'down') => {
        this.setState(
            (prevState) => {
                const estate = prevState.estate;
                const offset = direction === 'up' ? -1 : 1;
                const newPages = array.moveItem(estate.pages, page, offset);
                return {
                    ...prevState,
                    estate: {
                        ...estate,
                        pages: newPages,
                    },
                };
            },
            () => {
                EstateClient.reorder(this.state.estate.pages).catch(
                    this.showError,
                );
            },
        );
    };

    private async updateSlotParameter(
        updates: Partial<ITemplateParameter>,
        pageId: number,
        slotName: string,
    ) {
        return new Promise<void>((resolve) => {
            let paramToUpdate: ITemplateParameter;
            this.setState(
                (prevState) => {
                    const targetParam = this.getSlotTemplateParameter(
                        prevState,
                        pageId,
                        slotName,
                    );

                    paramToUpdate = { ...targetParam, ...updates };

                    return {
                        ...prevState,
                        estate: {
                            ...prevState.estate,
                            pages: this.updateParameterToPage(
                                pageId,
                                paramToUpdate,
                                prevState,
                            ),
                        },
                    };
                },
                () => {
                    this.updateParameter(paramToUpdate);
                    resolve();
                },
            );
        });
    }

    private updateParameterToPage(
        pageId: number,
        parameter: ITemplateParameter,
        prevState: IEstateStateControlState,
    ) {
        const page = prevState.estate.pages.find((p) => p.id === pageId)!;
        const prevTemplateParameter = page.parameters.find(
            (p) => p.id === parameter.id,
        )!;
        const newParameters = array.replace(
            page.parameters,
            prevTemplateParameter,
            parameter,
        );
        const newPage: IPage = {
            ...page,
            parameters: newParameters,
        };
        return array.replace(prevState.estate.pages, page, newPage);
    }

    private getSlotTemplateParameter(
        prevState: IEstateStateControlState,
        pageId: number,
        slotName: string,
    ): ITemplateParameter {
        return prevState.estate.pages
            .find((p) => p.id === pageId)!
            .parameters.find((param) => param.slotName === slotName)!;
    }

    private updateParameter(param: ITemplateParameter) {
        PageClient.updateParameter(param)
            .then(() => this.refreshThumbnail(param.pageId))
            .catch(this.showError);
    }

    private async refreshThumbnail(pageId: number) {
        PageClient.getPageThumbnail(pageId)
            .then((thumbnail) => {
                this.setState((prev) => {
                    return {
                        ...prev,
                        estate: prev.estate
                            ? {
                                  ...prev.estate,
                                  pages: prev.estate.pages.map((page) =>
                                      page.id === pageId
                                          ? {
                                                ...page,
                                                thumbnail,
                                            }
                                          : page,
                                  ),
                              }
                            : prev.estate,
                    };
                });
            })
            .catch(this.showError);
    }
}

import React from 'react';
import { container, sheet } from './styling';
import { IPageWithThreeImagesAndOneText } from './TemplateProps';
import { TemplateImage } from './TemplateImage';
import { TemplateTextEditor } from './TemplateTextEditor';
import { PreventMarginCollapse } from './PreventMarginCollapse';

export const Template7 = (props: IPageWithThreeImagesAndOneText) => {
    return (
        <div style={sheet}>
            <div style={container}>
                <TemplateImage img={props.img1} style={topSection} />
                <PreventMarginCollapse />
                <div style={bottomSection}>
                    <PreventMarginCollapse />
                    <div style={topSection}>
                        <TemplateImage img={props.img2} style={leftSection} />
                        <TemplateImage img={props.img3} style={rightSection} />
                    </div>
                    <PreventMarginCollapse />
                    <div style={bottomSection}>
                        <TemplateTextEditor
                            minHeight={240}
                            maxHeight={240}
                            text={props.text}
                            style={textSection}
                            hideOpacityControls={true}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

const topSection = {
    display: 'flex',
    overflow: 'hidden',
    width: '100%',
    height: '50%',
    marginBottom: '0.25cm',
    position: 'relative' as const,
};

const bottomSection = {
    display: 'flex',
    flexDirection: 'column' as const,
    width: '100%',
    height: '50%',
    marginTop: '0.25cm',
    position: 'relative' as const,
};

const leftSection = {
    display: 'inlineFlex',
    marginRight: '0.25cm',
    position: 'relative' as const,
    width: '50%',
};

const rightSection = {
    display: 'inlineFlex',
    marginLeft: '0.25cm',
    position: 'relative' as const,
    width: '50%',
};

const textSection = {
    display: 'inlineFlex',
    padding: '1px 1px 0 1px',
    height: '100%',
    width: '100%',
};

import React from 'react';
import { Classes, Overlay, Button, Intent, Callout } from '@blueprintjs/core';

export const ErrorOverlay = (props: {
    isOpen: boolean;
    summary?: string;
    error?: any;
    onClose: () => void;
}) => {
    return (
        <Overlay
            className={Classes.OVERLAY_SCROLL_CONTAINER}
            isOpen={props.isOpen}
            canEscapeKeyClose={false}
            canOutsideClickClose={false}
            enforceFocus={true}
            hasBackdrop={true}
        >
            {props.error && (
                <div
                    style={{
                        display: 'flex',
                        position: 'relative',
                        justifyContent: 'center',
                        height: '100%',
                    }}
                >
                    <div
                        style={{
                            background: 'white',
                            height: 'fit-content',
                            width: '100%',
                            margin: '50px',
                            padding: '20px',
                        }}
                    >
                        <div>
                            {props.summary ? (
                                <h2>{props.summary}</h2>
                            ) : (
                                <h2>Hups, jotain meni pieleen :(</h2>
                            )}
                            <Callout>
                                <details>
                                    <summary>Virheen tiedot</summary>
                                    {JSON.stringify(props.error)}
                                </details>
                            </Callout>
                            <br />
                            <br />
                            Ota muutoksesi talteen ja lataa sivu uudelleen.
                            <br />
                            Ilman uudelleenlataamista sivu jää virheelliseen
                            tilaan ja uudet muutokset eivät välttämättä talletu
                            oikein.
                            <br />
                            <br />
                            <Button
                                icon="confirm"
                                text="OK"
                                onClick={props.onClose}
                                intent={Intent.PRIMARY}
                            />
                        </div>
                    </div>
                </div>
            )}
        </Overlay>
    );
};

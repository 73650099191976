import {
    Icon as BpIcon,
    Intent,
    Tooltip,
    PopoverPosition,
} from '@blueprintjs/core';
import { IconName } from '@blueprintjs/icons';
import React from 'react';

export class Icon extends React.Component<{
    icon: IconName;
    tooltip: string;
    hovered: boolean;
    iconSize?: number;
    defaultOpacity?: number;
    onClick?: () => void;
}> {
    public render() {
        const defaultOpacity =
            this.props.defaultOpacity !== undefined
                ? this.props.defaultOpacity
                : 0.2;
        return (
            <Tooltip
                position={PopoverPosition.RIGHT}
                content={this.props.tooltip}
            >
                <BpIcon
                    icon={this.props.icon}
                    intent={Intent.PRIMARY}
                    iconSize={this.props.iconSize ? this.props.iconSize : 30}
                    style={{
                        opacity: this.props.hovered ? 1.0 : defaultOpacity,
                        cursor: this.props.hovered ? 'pointer' : 'default',
                    }}
                    onClick={this.props.onClick}
                />
            </Tooltip>
        );
    }
}

export class AutoHoverIcon extends React.Component<
    {
        icon: IconName;
        tooltip: string;
        iconSize?: number;
        onClick?: () => void;
    },
    { hovered: boolean }
> {
    public state = { hovered: false };

    public render() {
        return (
            <div
                onMouseOver={() => this.setState({ hovered: true })}
                onMouseOut={() => this.setState({ hovered: false })}
            >
                <Icon {...this.props} hovered={this.state.hovered} />
            </div>
        );
    }
}

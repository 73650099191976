import {
    Card,
    Elevation,
    Popover,
    Button,
    Intent,
    PopoverPosition,
    TextArea,
    Classes,
    H5,
} from '@blueprintjs/core';
import React from 'react';

export const CreateNewEstateCard = (props: {
    onNew: (name: string) => void;
}) => {
    return (
        <div className="list-view-item-create-new">
            <EstateDescriptionPopover onNew={props.onNew}>
                <Card
                    className="list-view-item-create-new-card"
                    elevation={Elevation.TWO}
                    interactive={true}
                >
                    <h5 className="list-view-item-create-new-text">Luo uusi</h5>
                    <img
                        className="list-view-item-create-new-thumbnail"
                        src="./new-page.png"
                    />
                </Card>
            </EstateDescriptionPopover>
        </div>
    );
};

class EstateDescriptionPopover extends React.Component<{
    onNew: (name: string) => void;
}> {
    public render() {
        let textRef: HTMLTextAreaElement | null;
        const setRef = (ref: HTMLTextAreaElement | null) => {
            textRef = ref;
        };
        return (
            <Popover position={PopoverPosition.RIGHT}>
                {this.props.children}
                <div style={{ padding: 10 }}>
                    <H5>Kohteen nimi</H5>
                    <TextArea
                        inputRef={setRef}
                        large={true}
                        intent={Intent.PRIMARY}
                        placeholder="Kohteen nimi..."
                    />
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            marginTop: 15,
                        }}
                    >
                        <Button
                            className={Classes.POPOVER_DISMISS}
                            text="Peruuta"
                        />
                        <Button
                            className={Classes.POPOVER_DISMISS}
                            style={{ marginLeft: 10 }}
                            onClick={() => {
                                const val = textRef!.value;
                                this.props.onNew(
                                    val !== '' ? val : 'Uusi kohde',
                                );
                            }}
                            intent={Intent.PRIMARY}
                            text="Lisää"
                        />
                    </div>
                </div>
            </Popover>
        );
    }
}

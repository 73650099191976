import { IEstate } from '../models/Estate';
import { IPage } from '../models/Page';
import { dataFromEstate, estateFromData } from './Mapper';
import { Api } from './Api';
import { IEstateJsonData } from './JsonDataObjects';

class EstateClient {
    public async getEstate(id: number): Promise<IEstate> {
        const response = await Api.get(`/api/estate/${id}`);
        const data = await response.json();
        const estate = estateFromData(data as IEstateJsonData);
        return estate;
    }
    public async getEstates(): Promise<ReadonlyArray<IEstate>> {
        const response = await Api.get('/api/estate/');
        const data = await response.json();
        return data.map((estateData: IEstateJsonData) =>
            estateFromData(estateData),
        );
    }

    public async create(name: string): Promise<IEstate> {
        const response = await Api.post('/api/estate/', { name: name });
        const data = await response.json();
        return estateFromData(data as IEstateJsonData);
    }

    public async update(estate: IEstate): Promise<IEstate> {
        const data = dataFromEstate(estate);
        const response = await Api.put(`/api/estate/${estate.id}/`, data);
        const json = await response.json();
        return estateFromData(json as IEstateJsonData);
    }

    public async delete(id: number): Promise<void> {
        // Don't really delete, just make it invisible
        await Api.put(`/api/estate/${id}/`, { id, is_removed: true });
    }

    public async restore(id: number): Promise<void> {
        await Api.put(`/api/estate/${id}/`, { id, is_removed: false });
    }

    public async reorder(pages: ReadonlyArray<IPage>): Promise<void> {
        const estateId = pages[0].estateId;
        const data = {
            pages: pages.map((page) => page.id),
        };
        await Api.post(`/api/estate/${estateId}/reorder/`, data);
    }
}

const client = new EstateClient();

export { client as EstateClient };

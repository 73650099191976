import React from 'react';
import { container, sheet } from './styling';
import { IPageWithThreeImages } from './TemplateProps';
import { TemplateImage } from './TemplateImage';
import { PreventMarginCollapse } from './PreventMarginCollapse';

export const Template10 = (props: IPageWithThreeImages) => {
    return (
        <div style={sheet}>
            <div style={container}>
                <TemplateImage img={props.img1} style={topSection} />
                <PreventMarginCollapse />
                <TemplateImage img={props.img2} style={middleSection} />
                <PreventMarginCollapse />
                <TemplateImage img={props.img3} style={bottomSection} />
            </div>
        </div>
    );
};

const topSection = {
    display: 'flex',
    overflow: 'hidden',
    width: '100%',
    height: '33%',
    marginBottom: '0.33cm',
    position: 'relative' as const,
};
const middleSection = {
    display: 'flex',
    overflow: 'hidden',
    width: '100%',
    height: '33%',
    marginTop: '0.17cm',
    marginBottom: '0.17cm',
    position: 'relative' as const,
};

const bottomSection = {
    display: 'flex',
    overflow: 'hidden',
    width: '100%',
    height: '33%',
    marginTop: '0.33cm',
    position: 'relative' as const,
};
